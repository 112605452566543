<template>
  <v-list>
    <c-list-item to="/" :label="$t('home.label')" icon="home" />

    <c-list-item to="/solutions" :label="$t('solution.labels')" icon="puzzle-outline" />
    <!-- <drawer-menu :label="$t('solution.labels')" icon="puzzle-outline">
      <solutions-menu class="ml-2" />
    </drawer-menu> -->

    <drawer-menu :label="$t('industry.label')" icon="factory">
      <industry-menu class="ml-2" />
    </drawer-menu>

    <drawer-menu :label="$t('service.labels')" icon="toolbox-outline">
      <services-menu class="ml-2" />
    </drawer-menu>

    <c-list-item to="/pricing" :label="$t('pricing.label')" icon="cash-multiple" />

    <drawer-menu :label="$t('company.label')" icon="domain">
      <company-menu class="ml-2" />
    </drawer-menu>

    <c-list-item to="/insights" :label="$t('blog.label')" icon="fountain-pen-tip" />

    <v-divider />

    <v-list-item>
      <v-list-item-icon><v-icon>mdi-cog-outline</v-icon></v-list-item-icon>

      <currency-setting selector-class="mr-4" />

      <select-locale />
    </v-list-item>

    <v-divider class="mb-4" />

    <social-media-links class="float-left ml-1" />

    <social-sharing class="mr-8 float-right" />
  </v-list>
</template>

<script>
import CONFIG from '~/assets/config/constants'
import DrawerMenu from '@/components/default/menu/_DrawerMenu.vue'

import IndustryMenu from '@/components/default/menu/IndustryMenu.vue'
import ServicesMenu from '@/components/default/menu/ServicesMenu'
// import SolutionsMenu from '@/components/default/menu/SolutionsMenu'
import CompanyMenu from '@/components/default/menu/CompanyMenu.vue'

import CurrencySetting from '@/components/_lib/CurrencySetting.vue'
import SocialMediaLinks from '@/components/_lib/SocialMediaLinks.vue'
import SelectLocale from '@/components/_lib/SelectLocale.vue'

import SocialSharing from '@/components/default/SocialSharing.vue'

export default {
  components: {
    CurrencySetting,
    SocialMediaLinks,
    DrawerMenu,
    ServicesMenu,
    // SolutionsMenu,
    CompanyMenu,
    IndustryMenu,
    SelectLocale,
    SocialSharing
  },
  data () {
    return {
      currencies: CONFIG.CURRENCY
    }
  }
}
</script>
