<!-- Show an Image and the optional zoomed dialog popup

  <c-img src="" />

  <c-img
        :alt="$t('solution.title')"
        :title="$t('solution.title')"
        :src="require(`@/assets/product/EBF-workflow-v05.svg`)"
        :aspect-ratio="1200/600"
        contain
        class="mx-2"
        light
      />

@NOTE: IMPORTANT INSTALLATION

1 - latest version does not work, https://github.com/thecodealer/vue-panzoom/issues/27, use instead:
npm install vue-panzoom@1.1.3

2- Add to nuxt.config.js in plugins: {
  { src: '@/plugins/panzoom' },
}

3 - in plugins/panzoom/index.js
  import Vue from 'vue'
  import VuePanZoom from 'vue-panzoom'

  Vue.use(VuePanZoom, { componentName: 'v-panzoom' })

-->

<template>
  <div class="d-flex align-center justify-center" style="width: 100%;">
    <div :class="{'cursor-zoom-in': !hideDialog}" style="display: grid; width: 100%;" @click.stop="openDialog()">
      <v-img
        contain
        :src="src"
        :alt="alt"
        v-bind="$attrs"
      />
    </div>

    <v-dialog v-if="dialog" v-model="dialog" fullscreen scrollable>

      <div style="overflow: hidden; height: 100% !important;">
        <v-system-bar window fixed :lights-out="!light" style="cursor: pointer;" @click="dialog=false;">
          <v-btn icon><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-spacer />
          <span class="caption">{{ title }}</span>
          <v-spacer />
          <v-btn icon class="mr-n2"><v-icon>mdi-close</v-icon></v-btn>
        </v-system-bar>

        <div
          style="height: 100% !important; "
          class="d-flex align-center justify-center "
          :class="light ? 'white darken-2' : 'grey darken-4' "
        >
          <v-panzoom v-if="totalSrc">
            <v-img
              id="current-image"
              :lazy-src="lazySrc"
              :src="totalSrc"
              :alt="alt"
            />
          </v-panzoom>
        </div>

        <div class="semi-transparent" style="position: absolute; right: 20px; bottom: 20px; ">
          <v-btn icon color="white" large @click="openNewWindow">
            <v-icon large>mdi-open-in-new</v-icon>
          </v-btn>
        <!-- <v-btn icon color="white" @click="downloadFile">
            <v-icon>mdi-download</v-icon>
          </v-btn> -->
        </div>
      </div>
    </v-dialog>

  </div>
</template>

<script>
// import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {
  props: {
    src: {
      type: [String, Object],
      default: ''
    },
    fullSrc: {
      type: String,
      default: ''
    },
    lazySrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    light: {
      type: Boolean,
      default: false
    },
    hideDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    totalSrc () {
      return this.fullSrc || this.src
    }
  },
  watch: {
    dialog () {
      if (this.dialog) {
        document.documentElement.classList.add('hide-scrollbar')
      } else {
        document.documentElement.classList.remove('hide-scrollbar')
      }
    }
  },
  methods: {
    openDialog () {
      if (!this.hideDialog) {
        // Log.i(`[image-view] fullSrc = ${this.fullSrc} lazySrc=${this.lazySrc}`)
        this.dialog = true
      }
    },
    openNewWindow () {
      window.open(this.fullSrc, '_blank')
    }
  }
}
</script>
