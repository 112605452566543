<!-- Show our standard formatted blockquote

Examples:
  <c-caption>I told you so.</c-caption>

To make margins same as a chapter:
  <c-caption chapter>{{ $t('home.score.caption') }}</c-caption>

-->

<template>
  <div>
    <template v-if="chapter">
      <v-container fluid>
        <v-row class="mt-0 mb-0">
          <v-col
            class="mt-0 mb-0"
            cols="12"
            :class="{ 'offset-lg-2': chapter }"
            :lg="chapter ? 8:12"
            align-center
          >
            <blockquote class="blockquote">
              <slot />
            </blockquote>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <blockquote v-if="!chapter" class="blockquote">
      <slot />
    </blockquote>
  </div>
</template>

<script>
export default {
  props: {
    chapter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
