/*  Easy use of Reactive App settings

    So no need for submit, commit or subscribe

*** FOR EXAMPLE USE:

import appSettingsMixin from '@/components/_lib/mixins/appSettings.js'

export default {
  mixins: [ appSettingsMixin ],

  // and then something like: <div v-if="app_setting.dark_mode">Very dark</div>
  // or:
  methods: {
    toggleDarkMode() {
      this.app_set([ 'dark_mode' , !this.app_setting.dark_mode ])  // Note the array
    }
  },
  // or:
  computed: {
    payTerm: {
      get () { return this.app_setting.pay_term },
      set (value) { this.app_set(['pay_term', value]) }
    }
  }
  // or, the most easy, using a the *data* from the mixin
  this.app_darkMode = !this.app_darkMode
}

NOTE:  please note that all parameters should be *preset* in store to make them reactive!!

*/

import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      app_bounceTimer: {}
    }
  },

  computed: {
    // reactive data:
    app_currency: {
      get () { return this.app_setting.currency },
      set (value) { this.app_set(['currency', value]) }
    },
    app_darkMode: {
      get () { return this.app_setting.dark_mode },
      set (value) { this.app_set(['dark_mode', value]) }
    },
    app_priceTab: {
      get () { return this.app_setting.price_tab },
      set (value) { this.app_set(['price_tab', value]) }
    },
    app_quoteItems: {
      get () { return this.app_setting.quote_items },
      set (value) { this.app_set(['quote_items', value]) }
    },
    // app_payTerm: {
    //   get () { return this.app_setting.pay_term },
    //   set (value) { this.app_set(['pay_term', value]) }
    // },

    // *de-bounced* reactive data (like for v-sliders)
    // app_postsPerYear: {
    //   get () { return this.app_setting.posts_per_year },
    //   set (value) { debounceSet(this, 'posts_per_year', value) }
    // },

    // reactive state app setting
    ...mapState({
      // example: this.app_setting.dark_mode
      app_setting: state => state.app
    })
  },
  methods: {
    ...mapMutations({
      // example: this.app_set(['dark_mode' , false])
      app_set: 'app/set'
    })
  }
}

// ***  HELPERS  ***

// Debounce function, use example: debounceSet (this, 'posts_per_year', value)
//
// function debounceSet (self, parameter, value) {
//   clearTimeout(self.app_bounceTimer[parameter])
//   self.app_bounceTimer[parameter] = setTimeout(function () {
//     self.app_set([parameter, value])
//   }, 250)
// }
