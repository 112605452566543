// *** APP CONFIGURATION ***
//
// Use:
//   import CONFIG from '~/assets/config/constants'
//   let v = CONFIG.VERSION
//
// Loaded in nuxt.config.js which will make it available as $config.
//   Careful to not use already existing params (!)
//
// To use in vuex
//  import Config from '@/config/constants.js'
//
//   Example
//     Log.i(`Version = ${$config.VERSION}`)

// ** Use REV for revisions that do *not* need new npm install
//    (which occur after changes in package.json)
//
const REV = "b";

// if (!process.browser) {
//   // for the server to be able to access the environment vars:
//   require('dotenv').config()
// }

// Bligman
const apiHosts = {
  development: "http://localhost:3030",
  test: "https://bapi.nuricon.net",
  staging: "https://bapi.nuricon.net",
  production: "https://api.bligson.com",
};
// const apiHost = apiHosts[process.env.NODE_ENV]
const bligsonApi = {
  host: apiHosts.production,
  assetData: "/data",
  pages: {
    endpoint: "/v1/pages",
    key: "BM_3290f90weWFttdw09",
  },
  posts: {
    endpoint: "/v1/posts",
    accountId: "A_100",
    key: "BM_A28-CI_c9qa28nj34remynrxgaf",
    defaultFilter: "&$sort[startAt]=-1",
    defaultTags: ["bligson", "blog"],
    tags: ["marketing", "sales", "strategy"],
    default: "S_0001",
  },
};
bligsonApi.pages.url = `${bligsonApi.host}${bligsonApi.pages.endpoint}?key=${bligsonApi.pages.key}`;
// ex: `${this.$config.BLIGSON_API.pages.url}&slug=terms-conditions`
bligsonApi.posts.url = `${bligsonApi.host}${bligsonApi.posts.endpoint}?accountId=${bligsonApi.posts.accountId}&key=${bligsonApi.posts.key}`;
// ex: `${this.$config.BLIGSON_API.posts.url}&tag=strategy&tag=sales&$limit=3`

export default {
  SITE: "bligson.com",
  NODE_ENV: process.env.NODE_ENV,
  VERSION: (process.env.npm_package_version || "0.0.0") + REV,
  SERIAL_VERSION: serialVersion(process.env.npm_package_version), // see function below

  BLIGSON_API: bligsonApi,

  // Locales: See nuxt.config.js
  // exchange rates defined in @/assets/config/financials.json
  CURRENCY: {
    EUR: "Euro",
    USD: "US Dollar",
    GBP: "British Pound",
  },

  SOCIAL_MEDIA: {
    Facebook: "https://www.facebook.com/bligson",
    LinkedIn: "https://www.linkedin.com/company/bligson/",
    Twitter: "https://twitter.com/bligson",
  },

  AUDIT_ADMIN_SECRET: "lorinthe-bligson", // process.env.AUDIT_ADMIN_SECRET,

  BLIGSON_MAIL: {
    api_end_point: "/api/mailer", // process.env.BLIGSON_MAIL_API_END_POINT, // used in server_middleware/mailer.js and in contact.vue
    smtp: {
      // host: '83.149.80.118',
      host: "smtp.leaseweb.com",
      port: 465, // or 587
      secure: true, // true for 465, false for other ports
      auth: {
        user: "moreinfo@bligson.com", // process.env.BLIGSON_MAIL_SMTP_AUTH_USER,
        pass: "CA?~|T=0CsgZ-/AB", // process.env.BLIGSON_MAIL_SMTP_AUTH_PASS
      },
    },
    // smtp: {
    //   host: 'smtp.gmail.com',
    //   port: 465,
    //   secure: true, // use SSL
    //   auth: {
    //     user: 'lorinthe.mailer.gmail.com',
    //     pass: 't9XuC8HvPRCn'
    //   }
    // },
    support: {
      from: "Bligson Corporate Website <moreinfo@bligson.com>",
      to: "moreinfo@bligson.com",
      // from: 'Qrespi <puck@qrespi.com>',
      // to: 'moreinfo@lorinthe.com'
    },
  },
  EDIT_SETTINGS: {
    // to determine reading time
    charactersPerWord: 5.8,
    wordsPerMinute: 275,
    maxLengthExcerpt: 500,
  },
};

// Calculate a serial version to compare versions if needed when migrating
//
function serialVersion(version) {
  // for example, 4.1.56 = 40156
  const v = version.split(".");
  return parseInt(v[0]) * 10000 + parseInt(v[1]) * 100 + parseInt(v[2]);
}
