<!-- Insert button with link. Conforming the nuxt and locale property

Example:
  <c-nav-button outlined color="white" to="/pricing/standard">{{ $t('pricing.label') }}</c-nav-button>
  <c-nav-button small text to="/company/contact">{{ $t('contact.label') }}</c-nav-button>
-->

<template>
  <v-btn
    nuxt

    v-bind="$attrs"
    :to="i18nTo(to)"
  >
    <slot />
  </v-btn>
</template>

<script>
import i18nLink from '@/components/_lib/mixins/i18nLink.mixin.js'

export default {

  mixins: [i18nLink],
  props: {
    to: {
      type: String,
      default: null // Denotes the target route of the link.
    }
  }
}
</script>
