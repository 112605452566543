<!-- Provide additional space on non-mobile screens

  Example:
    <c-spacer />
    <c-spacer half />
-->

<template>
  <div v-if="clear" class="clearfix" />
  <div v-else :class="spaceClass" />
</template>
<script>

export default {
  props: {
    half: {
      type: Boolean,
      default: false
    },
    clear: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    spaceClass () {
      return this.half
        ? 'mt-1 mb-1 mt-lg-3 mb-lg-3 mt-md-3 mb-md-3 mt-sm-2 mb-sm-2 clearfix'
        : 'mt-3 mb-3 mt-lg-7 mb-lg-7 mt-md-6 mb-md-6 mt-sm-4 mb-sm-4 clearfix'
    }
  }
}
</script>
