/* convert a link to an i18n link, based on cookie value
Example:

import i18nLink from '@/components/_lib/mixins/i18nLink.mixin.js'

export default {
  mixins: [ i18nLink ],

*/

// import Log from '@/components/_lib/log'
// import CONFIG from '~/assets/config/constants'

export default {
  methods: {
    i18nTo (value) {
      if (!value) { return '' }
      let locale = this.getCookie('site_language') || 'en'
      locale = locale === 'en' ? '' : '/' + locale
      return `${locale}${value}`
    },

    // 'clever' way to find a specific cookie name ...
    getCookie (name) {
      if (process.browser) {
        const value = '; ' + document.cookie
        const parts = value.split('; ' + name + '=')
        if (parts.length === 2) { return parts.pop().split(';').shift() }
      }
      return null
    }
  }
}
