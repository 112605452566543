/*
The user selectable App settings.
Will be remembered in local storage for next time user starts the app

Persistent state we use https://github.com/championswimmer/vuex-persist

Usage see:   @/components/_lib/mixins/appSettings.js
  example: this.app_set(['dark_mode' , !this.app_setting.dark_mode])

*/

import CONFIG from '~/assets/config/constants'
import FINANCIALS from '@/assets/config/financials.json'
import Log from '~/components/_lib/log'

//
// APP's PARAMETER STORE
//
// Note:  initialize parameters here to make them REACTIVE!!
//
export const state = () => ({
  serial_version: CONFIG.SERIAL_VERSION, // to remember what app version created these storage pars
  currency: FINANCIALS.currency.default, // 'EUR': valuta
  pay_term: FINANCIALS.pay_term.default, // how often pay: "yearly", "monthly"...
  // posts_per_year: CAT.posts_per_year.default, // # blog posts in a year: for volume discounts
  // quote_items: CAT.default_services, // list with items user selected
  price_tab: 0, // the open pane on the pricing tab, 1="custom" or 0="standard"
  dark_mode: false // reversed colors for easy reading at night
})

const getters = {
  //
  // GET example: store.getters['app/setting']('currency')
  //
  setting: state => (param) => {
    Log.i(`Getters param ${param}= ${state[param]}`)
    return state[param]
  }
}

const mutations = {
  //
  // SET: note the *array*: this.$store.commit('app/set', ['location', false ])
  //
  set (state, payload) {
    const PAR = payload[0]
    const VALUE = payload[1]
    state[PAR] = VALUE
    Log.i(`Set store: state.app.${PAR}= ${state[PAR]}`)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
  // actions
}
