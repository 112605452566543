//
// Save store state to localStorage so to remember user's settings
//
// See https://github.com/championswimmer/vuex-persist

// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      // only store these module's objects
      // NOTE: please check no console warnings when using the the vuex-tab in vue-devtools
      modules: ['app']
    }).plugin(store)
  })
}
