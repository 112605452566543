<!-- show inline info icon to click and show popup

   <c-info-button label="More info" class="ml-n2">
     yes sir !
   </c-info-button>

Or not with info-icon but click on whole row:

    <c-info-button icon="">
      <template v-slot:act>
        <v-icon>mdi-check</v-icon>
        <span class="primary--text">click here</span>
      </template>

      Interesting stuff
    </c-info-button>

-->
<template>
  <div>
    <v-btn v-if="icon" icon @click="dialog=true">
      <v-icon small :color="color">mdi-information-outline</v-icon>
    </v-btn>

    <span v-else class=" cursor-pointer" @click="showDialog()">
      <slot name="act" />
    </span>

    <v-dialog v-model="dialog" :max-width="800">
      <v-card>
        <v-system-bar :height="36" color="info">
          <v-spacer />
          <span class="overline white--text font-weight-bold">{{ label }}</span>
          <v-spacer />
          <v-btn icon @click="dialog=false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-system-bar>

        <v-card-text class="mt-4">
          <slot />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'info'
    },
    icon: {
      type: String,
      default: 'information-outline'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    showDialog () {
      if (!this.disabled) {
        this.dialog = true
      }
    }
  }
}
</script>
