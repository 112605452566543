// Register all components we like to use globally by this Plugin
//
// NOTE: Don't forget to load plugins in nuxt.config

import Vue from 'vue'

// global components
import Tooltip from './tooltip.vue'
import InfoButton from './infoButton.vue'

import Page from './_page.vue'
import Chapter from './_chapter.vue'
import CImg from './_image.vue'
// import CImg from './_chapterImg.vue'
import CUl from './_ul.vue'
import ListItem from './_listItem.vue'

import Caption from './_caption.vue'
import ResponsiveSpacer from './_responsiveSpacer.vue'

import NavButton from './_navButton.vue'
import Link from './_link.vue'

// used locally here
import Log from '~/components/_lib/log'
import CONFIG from '~/assets/config/constants'

// ** This Folder: @NOTE: Recommended **
Vue.component('c-tooltip', Tooltip)
Vue.component('c-info-button', InfoButton)

// *** Spread out folders, legacy
Vue.component('c-page', Page)
Vue.component('c-chapter', Chapter)
Vue.component('c-img', CImg)

Vue.component('c-list-item', ListItem) // for in menu, tables and so on. Note: use within <v-list><c-list-item .../></list-item></v-list>

Vue.component('c-ul', CUl) // nice simple <ul></ul> list with different mark icons

// Formatting helpers
Vue.component('c-caption', Caption) // nice looking caption
Vue.component('c-spacer', ResponsiveSpacer) // different space (mb & mt) on mobile, tablet and desktop

Vue.component('c-nav-button', NavButton) // different space (mb & mt) on mobile, tablet and desktop
Vue.component('c-link', Link) // different space (mb & mt) on mobile, tablet and desktop

Log.si('Bligson corporate website app version: ' + CONFIG.VERSION)
