<template>
  <v-footer app absolute class="grey--text text--darken-2">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <div class="d-flex tooltip-slot offset-lg-2 offset-xl-2" v-on="on">&copy;
          <span class="d-none d-sm-flex">&nbsp;copyright</span>
          <span>&nbsp;{{ thisYear }}</span>
        </div>
      </template>
      <span>version {{ version }} &nbsp;&copy; copyright {{ thisYear }} All rights reserved.</span>
    </v-tooltip>

    <v-spacer />
    <social-sharing class="pr-4" />
    <social-media-links />
    <v-spacer />

    <currency-setting responsive selector-class="px-2" />
    <select-locale responsive selector-class="pl-2" />
    <div class="d-none d-lg-flex col-lg-2 col-xl-2" />
  </v-footer>
</template>

<script>

import CurrencySetting from '@/components/_lib/CurrencySetting.vue'
import SocialMediaLinks from '@/components/_lib/SocialMediaLinks.vue'
import SelectLocale from '@/components/_lib/SelectLocale.vue'

import SocialSharing from '@/components/default/SocialSharing.vue'

export default {
  components: {
    CurrencySetting,
    SocialMediaLinks,
    SelectLocale,
    SocialSharing
  },
  data () {
    return {
      version: this.$config.VERSION,
      currencies: this.$config.CURRENCY,
      thisYear: new Date().getFullYear()
    }
  }
}
</script>
