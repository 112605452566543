<template>
  <v-app id="main">
    <!-- :hide-on-scroll="screenWidth < 800" -->
    <v-app-bar fixed app elevate-on-scroll :hide-on-scroll="allowFullScreen">
      <div style="width: 100%; max-width: 1280px;" class="d-flex mx-auto align-center">
        <c-link to="/" class="mt-1">
          <div>
            <logo class="d-none d-sm-flex" style="width: 150px;" />
            <logo class="d-flex d-sm-none" style="width: 120px;" />
          </div>
        </c-link>

        <v-spacer />
        <nav-top-bar class="d-none d-md-block" style="height: 40px;" />
        <v-spacer />

        <contact-button color="info" small outlined class="d-none d-sm-flex" />
        <!-- <signup-button color="info" small outlined class="d-none d-sm-flex" /> -->

        <v-app-bar-nav-icon
          aria-label="top menu"
          class="d-flex d-md-none"
          :class="{'rotate-button': drawer}"
          @click.stop="drawer = !drawer"
        />

        <div class="d-none d-lg-flex col-lg-2 col-xl-2">
          <v-spacer />
          <social-sharing />
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <nav-left-drawer />
    </v-navigation-drawer>

    <v-main>
      <nuxt />
    </v-main>

    <default-footer />
  </v-app>
</template>

<script>
// import Log from '@/components/_lib/log'
import Logo from '@/components/default/Logo.vue'
import NavLeftDrawer from '@/components/default/NavLeftDrawer.vue'
import DefaultFooter from '@/components/default/DefaultFooter.vue'
import NavTopBar from '@/components/default/NavTopBar.vue'
import SocialSharing from '@/components/default/SocialSharing.vue'
// import SignupButton from '@/components/_lib/templates/SignupButton'
import ContactButton from '@/components/_lib/templates/ContactButton'

export default {
  name: 'Default',
  components: {
    Logo,
    NavTopBar,
    NavLeftDrawer,
    DefaultFooter,
    SocialSharing,
    ContactButton
    // SignupButton
  },
  data () {
    return {
      drawer: false // show / hide the left drawer pane with menu items
    }
  },
  computed: {
    allowFullScreen () {
      return this.$route.path.includes('/blog/post/')
    }
  },
  created () {
    // set light theme on first page load
    this.$vuetify.theme.dark = false
  }
}
</script>

<style lang="scss">
.rotate-button {
  transform: rotate(40deg);
}

// Load Globals here ONCE !
@import "~/assets/styles/typography.scss";
@import "~/assets/styles/blogposts.scss";
@import "~/assets/styles/display.scss";
</style>
