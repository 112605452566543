/**
  A Lorinthe project.

  @Author: Tom Voorneveld <tom>
  @Date:   2019-04-28T17:44:34+03:00
  @Email:  tom@lorinthe.com
  @Project: Lorinthe
  @Last modified by:   tom
  @Last modified time: 2019-04-29T17:40:25+03:00
  @Copyright: (c) copyright Lorinthe BV, all rights reserved

  Show log line in console, if we are in debug mode:

    import Log from '@/components/_lib/log'

    Log.i('It is working')
    Log.n(`[!] Button ${i} tap`)

*/

// ** Debug parameter
//
const OVERRIDE_DEV_MODE = 'dev' // 'prod' || 'test' || 'dev' = no override

// Get RUN Environment
let RE

if (process.browser && window) {
  if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
    RE = OVERRIDE_DEV_MODE
  } else if (window.location.href.includes('nuricon.net')) {
    RE = 'test'
  } else {
    RE = 'prod'
  }
} else {
  RE = 'srv'
}

// if (process.browser) {
//   console.log(`Browser logging activated on: ${RE}`)
// } else {
//   console.log(`Server logging activated on: ${RE}`)
// }

const I_PRE = '[i]'
const E_PRE = '[!]'
const W_PRE = '[?]'
// const A_PRE = '[*]'

const Log = {
  // log on all client installations
  e (log1, log2 = ' ', log3 = ' ') { console.error(E_PRE, log1, log2, log3) }, // error
  w (log1, log2 = ' ', log3 = ' ') { console.warn(W_PRE, log1, log2, log3) }, // warn
  n (log1, log2 = ' ', log3 = ' ') { console.info(I_PRE, log1, log2, log3) }, // note

  // on test and dev only, so not on production installations
  a (log1, log2 = ' ', log3 = ' ') { if ('dev,test'.includes(RE)) { console.warn(W_PRE, log1, log2, log3) } }, // alert
  i (log1, log2 = ' ', log3 = ' ') { if ('dev,test'.includes(RE)) { console.info(I_PRE, log1, log2, log3) } }, // info

  // on dev only
  v (log1, log2 = ' ', log3 = ' ') { if ('dev'.includes(RE)) { console.log(log1, log2, log3) } }, // verbose

  // for server only logging
  se (log1, log2 = ' ', log3 = ' ') { if (!process.browser) { console.error(E_PRE, log1, log2, log3) } }, // error
  sw (log1, log2 = ' ', log3 = ' ') { if (!process.browser) { console.warn(W_PRE, log1, log2, log3) } }, // warn
  sn (log1, log2 = ' ', log3 = ' ') { if (!process.browser) { console.info(I_PRE, log1, log2, log3) } }, // note

  sa (log1, log2 = ' ', log3 = ' ') { if (!process.browser) { console.warn(W_PRE, log1, log2, log3) } }, // alert
  si (log1, log2 = ' ', log3 = ' ') { if (!process.browser) { console.info(I_PRE, log1, log2, log3) } } // info
}

export default Log
