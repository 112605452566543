<template>
  <div>
    <c-list-item
      v-for="(item, index) in SOLUTIONS"
      :key="index"
      dense
      icon-color="grey"
      :icon="item.icon"
      :to="`/solutions#${item.id}`"
      :label="$t(`solution.${item.id}.label`)"
    />
  </div>
</template>

<script>
import SOLUTIONS from '@/assets/config/solutions.json'

export default {
  components: {
  },
  data () {
    return {
      SOLUTIONS
    }
  }
}
</script>
