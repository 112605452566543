<template>
  <div>
    <c-list-item
      v-for="(item, index) in INDUSTRIES"
      :key="index"
      icon-small
      :icon="item.icon"
      dense
      :to="`/industry#${item.id}`"
      :label="$t(`industry.${item.id}.label`)"
    />
  </div>
</template>

<script>
import INDUSTRIES from '@/assets/config/industries.json'

export default {
  data () {
    return {
      INDUSTRIES
    }
  }
}
</script>
