<template>
  <div>
    <template v-for="(itemId, index) in serviceMenuIds">
      <!-- <drawer-menu
        v-if="SERVICES[itemId].parent"
        :key="index"
        :label="$t(`service.categories.${itemId}.label`)"
        :icon="SERVICES[itemId].icon"
        icon-color="grey"
        sub-group
        eager
        class="ml-n4"
      >
        <services-sub-menu :category-id="item.id" />
      </drawer-menu> -->

      <!-- no item.parent so no submenu needed -->
      <!-- v-else -->
      <c-list-item
        :key="index"
        icon-color="grey"
        :icon="SERVICES[itemId].icon"
        dense
        :to="`/service/${itemId}`"
        :label="$t(`service.${itemId}.label`)"
      />
    </template>
  </div>
</template>

<script>
import SERVICES from '@/assets/config/services.json'

// import DrawerMenu from '@/components/default/menu/_DrawerMenu.vue'
// import ServicesSubMenu from '@/components/default/menu/ServicesSubMenu.vue'

export default {
  components: {
    // DrawerMenu,
    // ServicesSubMenu
  },
  data () {
    return {
      SERVICES,
      serviceMenuIds: [
        'coaching',
        'deliverables',
        'bligsy',
        'framework'
      ]
    }
  }
}
</script>
