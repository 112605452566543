<!-- Example:
  <c-ul title="Title list" title-color="info" icon-color="primary" li-msg-prefix="service" icon="check" />
  <c-ul title="Title list" :li-msg-prefix="`services.service.${id}`" icon="check" />

  This would expect to have i18n labels of (coffeescript format):
  services:
    1: 'item 1'
    2: 'item 2'
    3: 'item 3'

-->

<template>
  <ul v-if="$te(`${liMsgPrefix}.1`, 'en')" style="list-style: none; padding-left: 0;">
    <h4 v-if="title" :class="`mb-2 ${titleColor}--text`" v-html="title" />
    <template v-for="i in 20">
      <li v-if="$te(`${liMsgPrefix}.${i}`, 'en')" :key="i" class="d-flex flex-row align-start">
        <v-icon :color="iconColor" class="pr-2 ">{{ `mdi-${icon}` }}</v-icon>
        <div v-html="$t(`${liMsgPrefix}.${i}`)" />
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'check'
    },
    titleColor: {
      type: String,
      default: 'primary'
    },
    iconColor: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: ''
    },
    liMsgPrefix: {
      type: String,
      default: ''
    }
  }
}
</script>
