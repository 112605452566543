var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mb-0 pb-0 mx-auto",staticStyle:{"max-width":"1920px"},attrs:{"fluid":""}},[(_vm.anchor)?_c('div',{staticClass:"scroll-margin-top",attrs:{"id":_vm.id}}):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"mb-0 pb-0",class:{'d-flex align-center': _vm.alignCenter}},[(_vm.iTitle && _vm.wideTitle)?_c('v-col',{class:{
        'offset-lg-2': !_vm.narrow && (!_vm.imgLeft || !_vm.imgSrc),
        'offset-lg-3': _vm.narrow && (!_vm.imgLeft || !_vm.imgSrc),
        'offset-md-1': _vm.narrow },attrs:{"cols":"12"}},[_c('c-spacer',{attrs:{"half":""}}),_vm._v(" "),_c('h1',{staticClass:"info--text my-4 mb-sm-6",class:{ center: _vm.titleCenter || _vm.$vuetify.breakpoint.smAndDown}},[_vm._v(_vm._s(_vm.iTitle))])],1):_vm._e(),_vm._v(" "),(_vm.imgSrc && _vm.imgLeft)?_c('v-col',{staticClass:"d-none d-lg-block pr-4",class:{ 'align-self-lg-center': !_vm.imgTop },staticStyle:{"margin-bottom":"-100px"},attrs:{"cols":"12","lg":_vm.narrow ? 3 : 2}},[_c('v-img',{staticClass:"d-none d-lg-block",attrs:{"alt":_vm.imgAlt,"src":_vm.imgSrc,"height":_vm.imgMaxHeight,"contain":"","position":"bottom"}})],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"pt-0 pb-0",class:{
        'offset-lg-2': !_vm.narrow && (!_vm.imgLeft || !_vm.imgSrc),
        'offset-lg-3': _vm.narrow && (!_vm.imgLeft || !_vm.imgSrc),
        'offset-md-1': _vm.narrow },attrs:{"cols":"12","sm":_vm.splitCol ? ( _vm.wideTitle ? 6 : 5 ) : ( _vm.narrow ? 10 : 12 ),"lg":_vm.splitCol ? ( _vm.wideTitle ? 4 : 3 ) : ( _vm.narrow ? 6 : 8 )}},[(_vm.iTitle && !_vm.wideTitle)?[_c('c-spacer',{attrs:{"half":""}}),_vm._v(" "),_c('h1',{staticClass:"info--text my-4 mb-sm-6",class:{ center: _vm.titleCenter || _vm.$vuetify.breakpoint.smAndDown}},[_vm._v(_vm._s(_vm.iTitle))])]:_vm._e(),_vm._v(" "),(_vm.iSubtitle)?[_c('c-spacer',{attrs:{"half":""}}),_vm._v(" "),_c('h1',{staticClass:"d-block my-sm-4",class:{ center: _vm.titleCenter },domProps:{"innerHTML":_vm._s(_vm.iSubtitle)}}),_vm._v(" "),_c('c-spacer')]:_vm._e(),_vm._v(" "),(_vm.iIntro)?[_c('c-spacer',{attrs:{"half":""}}),_vm._v(" "),_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(_vm.iIntro)}})]:[_vm._t("intro")],_vm._v(" "),(!_vm.splitCol)?_vm._t("default"):_vm._e()],2),_vm._v(" "),(_vm.splitCol)?_c('v-col',{attrs:{"cols":"12","sm":( _vm.wideTitle ? 6 : 7 ),"lg":( _vm.wideTitle ? 4 : 5 )}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"d-none d-lg-block pt-3",attrs:{"cols":"12","lg":_vm.narrow ? 3 : 2}},[((_vm.imgSrc && !_vm.imgLeft))?_c('v-img',{class:{ 'align-self-lg-center': !_vm.imgTop },staticStyle:{"margin-bottom":"-100px"},attrs:{"alt":_vm.imgAlt,"src":_vm.imgSrc,"height":_vm.imgMaxHeight,"contain":"","position":"bottom"}}):_vm._e(),_vm._v(" "),_vm._t("rightborder")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }