<!--  *** Default Page Template; probably all but home page:

Example:
  <c-page
    hero-text-color="accent"
    hero-color="info"
    :hero-image= "require('@/assets/hero/contact.svg')"
    :label="$t('partners.label')"
    :title="$t('partners.title')"
    :subtitle="$t('partners.subtitle')"
    :intro="$t('partners.intro')"
  >
    [...]
  </c-page>

Instead of prop as intro, you can use slot as well:
  <c-page>
    <template v-slot:intro>
      <p v-html="$t('services.timeline.intro')" />
    </template>
  </c-page>

Note: no need to import as it is part of ~/plugins/components.js

If no Hero image, then just displays bar (sheet) with hero-color
If hero image, suggest to still use hero-color to avoid flashing lazy loading of parallax image.
  So choose best matching color

-->

<template>
  <section class="clearfix pb-6 mx-auto" :class="{'dark': dark}" style="max-width:1920px;">
    <c-spacer half />

    <template v-if="label">
      <h2 class="text-uppercase offset-lg-2 offset-xl-2 pl-4">{{ label }}</h2>
      <c-spacer />
    </template>

    <!-- show subtitle above title if we do not have a hero image to put it in -->
    <v-row class="offset-lg-2 offset-xl-2 pl-3 pl-lg-0 pr-6" dense>
      <v-col :class="headerImgSrc ? 'col-10 col-md-11' : ''">
        <template v-if="!heroImage && subtitle">
          <c-spacer half />
          <h4 class="mt-4 mb-2 pr-3 text-uppercase">{{ subtitle }}</h4>
          <c-spacer half />
        </template>

        <template v-if="title">
          <h1 :class="titleClass" class="pr-3">{{ title }}</h1>
          <c-spacer />
        </template>
      </v-col>

      <template v-if="headerImgSrc">
        <v-img
          class="flex-column col-2 col-md-1"
          :class="headerImgClass"
          :src="headerImgSrc"
          max-height="200"
          contain
          :style="headerImgMirror ? 'transform: scaleX(-1);' : ''"
        />
      </template>
    </v-row>

    <!-- Hero bar only, possibly with text -->
    <template v-if="(heroColor || heroText) && !heroImage">
      <!-- large screens -->
      <v-sheet class="d-none d-md-flex py-2" :color="heroColor" tile>
        <div
          v-if="heroText"
          class="offset-lg-2 offset-xl-2 col-8 pl-3 pr-4 py-8"
          :class="`${heroTextColor}--text`"
          v-html="heroText"
        />
      </v-sheet>
      <!-- small screens -->
      <v-sheet class="d-flex d-md-none py-1" :color="heroColor" tile style="width:100%;">
        <div v-if="heroText" class="pl-5 pr-3 py-4 font-weight-light" :class="`${heroTextColor}--text`" v-html="heroText" />
      </v-sheet>
    </template>

    <!-- Full parallax here image -->
    <template v-if="heroImage">
      <v-parallax
        class="text-center align-self-center"
        :class="heroColor"
        height="120"
        :src="imgSrc"
      >
        <v-row align="center" :class="`${heroTextColor}--text`">
          <v-col class="col-sm-8 offset-sm-2 mt-0">
            <div class="title shadow" v-html="subtitle" />
          </v-col>
        </v-row>
      </v-parallax>
    </template>

    <c-chapter v-if="intro" :intro="intro" />
    <!-- <c-spacer half />
    <template v-if="intro || isIntroSlot">
      <c-chapter v-else >
        <slot name="intro" />
      </c-chapter>
    </template>
    <c-spacer v-else half /> -->
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    dark: { type: Boolean, default: false },

    label: { type: String, default: '' },
    title: { type: String, default: '' },
    titleClass: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    intro: { type: String, default: '' },

    // small image before the title block
    headerImgSrc: { type: String, default: '' },
    headerImgClass: { type: String, default: '' }, // e.g. "d-sm-none"
    headerImgMirror: { type: Boolean, default: false },

    // horizontal image full page width
    heroText: { type: String, default: '' },
    heroTextColor: { type: String, default: 'white' },
    heroImage: { type: String, default: '' },
    heroColor: { type: String, default: '' }
  },
  data () {
    return {
      // do not pass directly as prop, otherwise hero Parallax will not load
      imgSrc: this.heroImage
    }
  },
  mounted () {
    if (this.$route.hash) {
      this.scrollTo(this.$route.hash)
    }
  },
  methods: {
    scrollTo (hashtag) {
      setTimeout(() => {
        location.href = hashtag
        this.$scrollTo(hashtag, 500, { offset: -70 })
      }, 500)
    }
  }
}
</script>
