/*
*  Store to keep cached copy of the grades of the various audit tests
*
*/

import Audits from '@/assets/config/audits.json'

export const state = () => ({
  grades: {},
  // totalGrade: 0,
  fields: {
    f_url: null, // client website
    f_eml: null, // email
    f_ind: null // industry
  },
  tests: Audits.tests

})

export const mutations = {
  // e.g: this.$store.commit('audit-test/grade', { id: this.testBlock.id, value: newValue })
  grade (state, auditTest) {
    state.grades[auditTest.id] = auditTest.value
    // console.log(auditTest.id, auditTest.value)
  },
  // e.g: this.$store.commit('audit-test/field', { set: 'url', value: 'https://lorinthe.com' })
  field (state, field) {
    state.fields[field.set] = field.value
  },
  // e.g: this.$store.commit('audit-test/totalGrade', 123 )
  totalGrade (state, total) {
    state.totalGrade = total
  }

}

export const getters = {
  // ** Get app setting
  // e.g: grade = this.$store.getters['audit-test/grade']('blog_top_menu')
  grade: state => (id) => {
    return state.grades[id]
  },
  // e.g: grade = this.$store.getters['audit-test/field']('f_url')
  field: state => (field) => {
    return state.fields[field]
  },
  grades: state => () => {
    return state.grades || {}
  },
  nr_tests: state => () => {
    return Object.keys(state.grades).length || 0
  },
  // e.g: total = this.$store.getters['audit-test/totalGrade']()
  /* eslint no-prototype-builtins: "off" */
  average_grade: state => () => {
    let totalValue = 0.0
    let totalFactor = 0.0
    state.tests.forEach((T) => {
      if (state.grades.hasOwnProperty(T.id)) {
        const VAL = state.grades[T.id]
        // if the score is minimal AND there is different weighing factor for the min value, use that one
        const MIN = T.hasOwnProperty('min') ? T.min : 0
        const FACT = T.min_fact && (VAL === MIN) ? T.min_fact : T.fact
        // calculate total points and the division factor
        totalValue += VAL * FACT
        totalFactor += FACT
      }
    })
    return parseInt(totalValue / totalFactor) || 0
  }
}
