<!-- Show a sub menu

      <drawer-menu
        label="Workshops"
        dense
        icon="human-male-board"
        icon-small
        sub-group
        eager
      >
        <workshop-menu class="pl-2" />
      </drawer-menu>
-->

<template>
  <v-list-group
    v-bind="$attrs"
    prepend-icon=""
    :sub-group="subGroup"
    @click="marker = !marker"
  >
    <template v-slot:activator>
      <c-list-item
        :label="label"
        :to="to"
        :dense="dense"
        :icon="icon"
        :icon-small="iconSmall"
        :icon-color="iconColor"
        class="pl-0"
      />
      <v-icon v-if="subGroup">mdi-chevron-{{ marker ? 'up' : 'down' }} </v-icon>
    </template>

    <slot />
  </v-list-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Menu Group Label'
    },
    to: {
      type: String,
      default: ''
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'check'
    },
    iconSmall: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      marker: false
    }
  }
}
</script>
