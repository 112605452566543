<!--  Insert simple link, conforming the nuxt and locale property

Examples:

  <c-link :to="to" class="ml-1 link">Go SPA</c-link>
  <c-link href="https://www.duck.com">Duck Duck Go</c-link>

-->

<template>
  <span>
    <a
      v-if="href"
      :href="href"
      class="link"
      :target="target"
    ><slot /></a>

    <nuxt-link
      v-else-if="to"
      exact
      :to="i18nTo(to)"
      :target="target"
      class="link"
    ><slot /></nuxt-link>

    <template v-else>
      <slot />
    </template>
  </span>
</template>

<script>
import i18nLink from '@/components/_lib/mixins/i18nLink.mixin.js'

export default {
  mixins: [i18nLink],
  props: {
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    }
  }
}
</script>
