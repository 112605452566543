<!-- Show item that fits in the left navigation drawer

 label = printed as item title
 to = internal link
 href = external link

 Examples:
  <c-list-item label="home.label" two-line to="index" icon="home" icon-after/>
  <c-list-item label="google.label" label-class="body-2" href="https://www.google.com" icon="home" icon-after/>
  <c-list-item label="services.list.editing" icon="cash-100"/>
-->

<template>
  <v-list-item
    :two-line="twoLine"
    :to="i18nTo(to)"
    v-bind="$attrs"
    nuxt
    exact
    :ripple="false"
    :dense="dense"
    style="min-height: 40px; "
    class="nav-item"
  >
    <v-list-item-icon v-if="icon && !iconAfter" class="mt-1 mr-3" :class="{ 'mt-2 mr-0': dense}">
      <v-icon :small="iconSmall" :color="iconColor">mdi-{{ icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-title
      v-if="!twoLine"
      :style="dense ? 'font-size:0.9em;' : 'font-size: inherit;'"
      class="font-weight-regular text-uppercase d-flex"
      :class="{ 'ml-n1': iconSmall}"
    >
      <span :class="labelClass">{{ label }}</span>
      <slot />
    </v-list-item-title>

    <span v-else :class="labelClass" style="line-height: 1.3em;">
      <span>{{ label }}</span>
      <slot />
    </span>

    <v-list-item-icon v-if="icon && iconAfter">
      <v-icon>mdi-{{ icon }}</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import i18nLink from '@/components/_lib/mixins/i18nLink.mixin.js'

export default {

  mixins: [i18nLink],
  props: {
    label: { type: String, default: '' },
    labelClass: { type: String, default: '' },
    // info: { type: String, default: '' }, // ??? why is this, not used?
    dense: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: '' },
    iconSmall: { type: Boolean, default: false },
    twoLine: { type: Boolean, default: false },
    iconAfter: { type: Boolean, default: false },
    to: { type: String, default: '' }
  }
}
</script>
