<!--
Example:

<top-nav-menu label="Services"><service-menu /></nav-menu>
-->

<template>
  <v-menu
    dense
    open-on-hover
    bottom
    :close-on-content-click="false"
    :offset-y="true"
  >
    <template v-slot:activator="{ on }">

      <!-- <c-link v-slot="{ href, route, navigate, isActive, isExactActive }" :to="to"> -->
      <nuxt-link
        v-slot="{ isActive }"
        :to="i18nTo(topLevel || to)"
        custom
      >
        <v-btn
          nuxt
          small
          text
          :class="isActive ? 'background-1' :''"
          :to="i18nTo(to)"
          v-on="on"
        >{{ label }}</v-btn>
      </nuxt-link>
      <!-- :color="isActive ? 'primary' :''" -->

    </template>

    <v-list expand :min-width="300" :style="minHeight ? `min-height: ${minHeight}; overflow-y: auto;` : ''">
      <slot />
    </v-list>
  </v-menu>
</template>

<script>
import i18nLink from '@/components/_lib/mixins/i18nLink.mixin.js'

export default {

  mixins: [i18nLink],
  props: {
    label: {
      type: String,
      default: 'Menu Label'
    },
    // the page to go to when clicking on topmenu  button, example '/company/about'  if there is no '/company' page
    to: {
      type: String,
      default: null
    },
    // optional: example '/company', default = this.to
    topLevel: {
      type: String,
      default: null
    },
    minHeight: {
      type: String,
      default: null
    }
  }
}
</script>
