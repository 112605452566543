<template>
  <div>
    <c-list-item
      v-for="(m, index) in menuItems"
      :key="index"
      icon-small
      :icon="m.icon"
      dense
      :to="`/company/${m.item}`"
      :label="$t(`company.${m.item}.label`)"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      menuItems: [
        { item: 'about', icon: 'hammer' },
        // { item: 'partners', icon: 'handshake' },
        { item: 'terms', icon: 'file-document-edit-outline' },
        { item: 'contact', icon: 'mail' }

        // { item: 'job', icon: 'face-man-outline' },
        // { item: 'team', icon: 'account-multiple-outline' },
        // { item: 'faq', icon: 'comment-question-outline' },
      ]
    }
  }
}
</script>
