<template>
  <div>
    <v-icon
      size="20"
      color="primary"
      aria-label="social share"
      @click.stop="shareDialog = true"
      >{{ icn.mdiShareVariant }}</v-icon
    >

    <v-dialog v-model="shareDialog" max-width="500">
      <v-card class="pb-4">
        <v-card-title class="title">
          {{ $t("def.share") }}
          <v-spacer />
          <v-icon @click.stop="shareDialog = false">{{ icn.mdiClose }}</v-icon>
        </v-card-title>

        <v-card-text>
          <p class="overline">{{ url }}</p>
          <share-email
            :page_url="url"
            title_social="Email"
            has_icon
          /><!-- has_counter -->
          <share-facebook :page_url="url" title_social="Facebook" has_icon />
          <share-linked-in :page_url="url" title_social="LinkedIn" has_icon />
          <share-twitter :page_url="url" title_social="Twitter" has_icon />
          <share-telegram :page_url="url" title_social="Telegram" has_icon />
          <share-whatsapp :page_url="url" title_social="WhatsApp" has_icon />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import shareEmail from "vue-goodshare/src/providers/Email.vue";
import shareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import shareLinkedIn from "vue-goodshare/src/providers/LinkedIn.vue";
import shareTwitter from "vue-goodshare/src/providers/Twitter.vue";
import shareTelegram from "vue-goodshare/src/providers/Telegram.vue";
import shareWhatsapp from "vue-goodshare/src/providers/WhatsApp.vue";

import { mdiShareVariant, mdiClose } from "@mdi/js";

export default {
  components: {
    shareEmail,
    shareFacebook,
    shareLinkedIn,
    shareTwitter,
    shareTelegram,
    shareWhatsapp,
  },
  data: () => ({
    shareDialog: false,
    icn: {
      mdiShareVariant,
      mdiClose,
    },
  }),
  computed: {
    url() {
      if (this.shareDialog) {
        // little trick to force reactivity on each new page
        return window.location.href || "https://bligson-com-legacy.bligson.com";
      }
      return "https://bligson-com-legacy.bligson.com";
    },
  },
  mounted() {
    // if (process.browser) {
    //   this.url = window.location.href
    // }
  },
};
</script>
