<template>
  <c-page
    hero-text-color="white"
    hero-color="primary"
    :hero-image="require('@/assets/hero/error.svg')"
    :title="$t('error.title')"
    :subtitle="$t('error.subtitle')"
  >
    <c-chapter>
      <h1 v-if="error.statusCode === 404">{{ $t('error.page_not_found') }}</h1>
      <h1 v-else>{{ $t('error.other') }}</h1>
      <v-divider />
      <NuxtLink to="/">
        Home page
      </NuxtLink>
    </c-chapter>
  </c-page>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageNotFound: '',
      otherError: 'An error occurred'
    }
  },
  head () {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
