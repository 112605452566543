export default

  home:
    label: 'Home'
    company: 'Bligson'
    title: 'Achievement Recognition Platform'
    description:
      1: 'acknowledge workers\' experiences'
      2: 'highlight their best achievements'
      3: 'reduce employee churn'
    punchline: 'tell their story'
    platform: 'bot, online platform & services'
    benefits:
      1: 'find the best'
      2: 'increase loyalty'
      3: 'feed the team'
    problem:
      title: 'The Problem'
      description: 'In about every {industries_link} many workers are unhappy: they are stressed and {wiki_link} A major cause appears to be the {appreciation_link} It is a serious problem that deserves to be addressed.'
      wiki_link:
        url: 'https://en.wikipedia.org/wiki/Great_Resignation'
        tag: 'leave their job.'
      industries_link:
        tag: 'industry,'
      appreciation_link:
        url: 'https://www.mckinsey.com/business-functions/people-and-organizational-performance/our-insights/great-attrition-or-great-attraction-the-choice-is-yours'
        tag: 'perceived lack of appreciation.'
      
    why_bligson:
      title: 'Why Bligson'
      1:
        title: 'recognize experiences'
        description: 'Many employee {experience_link} go unnoticed; our bot {bot_link} collects them all.'
        experience_link:
          tag: 'experiences'
        bot_link:
          tag: 'Bligsy, '
        more: 'Positive experiences are the basis of each achievement. We need to find these experiences It is a daily, ongoing task which you can do yourself or ask an independent {coach_link} to do it for you. Our experience assembly bot, {bligsy_link} can do most of the work though. With our {app_link} you can efficiently manage the whole process.'
        coach_link:
          tag: 'achievement coach'
        bligsy_link:
          tag: 'Bligsy'
        app_link:
          tag: 'Achievement Recognition Platform,'
      2:
        title: 'promote achievements'
        description: '{stories_link} will give you a great set of content for any publication.'
        stories_link:
          tag: 'Achievement stories'
        more: 'Stories help people to believe in themselves as outlined by a recent {harvard_link} Therefore, we recommend to collect and publish stories with the individual employee\'s achievements and insights. You can use the achievements for internal and external promotion, social media, blogs, YouTube, presentations, campaigns and so much more. We have great  {pt_link} so you do not need to reinvent the wheel and will create the best {campaigns_link} in the least amount of time.'
        pt_link:
          tag: 'pipeline-templates'
        campaigns_link:
          tag: 'media campaigns'
        harvard_link:
          url: 'https://hbr.org/2005/01/whats-your-story'
          tag: 'Harvard study.'
      3:
        title: 'reveal purpose'
        description: 'People feel {valued_link} seeing others appreciating their achievements.'
        valued_link:
          tag: 'valued and proud'
        more: 'Employers often overlook why their employees are truly leaving. Often they do not realize it is ´lack of belonging´ or not being ´valued by their manager´. Appreciation is therefor not a one-time action but instead an all important and continuously {attraction_link}. When they leave the office at the end of a day, your colleagues should have a true sense of accomplishment.'
        attraction_link:
          url: 'https://www.mckinsey.com/business-functions/people-and-organizational-performance/our-insights/great-attrition-or-great-attraction-the-choice-is-yours'
          tag: 'ongoing process (McKinsey)'
      4:
        title: 'sense their pulse'
        description: 'Realtime {analytics_link} in employee happiness is vital to the health of any organization.'
        analytics_link:
          tag: 'insight'
        more: '{bligsy_link} not only collects experiences, she also gathers actual {data_link} for the most important staff metric. In fact with Bligson you will automatically measure how happy your employees are and in what direction their satisfaction is evolving. Thanks to this data, you can react quickly and turn awareness into improvements and back into actions.'
        bligsy_link:
          tag: 'Bligsy'
        data_link:
          tag: 'data'
   
    stepper:
      title: 'What we do'
      intro: 'Our {app_link}, e.g. achievement recognition Platform, helps you to collect stories that describe the accomplishments of employees or members. With special campaign planning tools, you portrait the stories as showcase of you as attractive organization. You can actually proof you care!'
      app_link:
        tag: 'online application '
      knowledge_link:
        tag: 'knowledge bank'
      pay_off:
        text: 'A good {0} guides you through the important steps of your workflow. '
        fw: ' framework '
   
    why:
      title: 'Bligson, tell <u>their</u> story'
 
    testimonial:
      title: 'A stronger employer strategy'
      subtitle: 'What they are saying'
      intro: ''
      1: 'Organizations that figure out how to tell emotionally charged stories are the ones to succeed.'
      2: 'The hybrid model for work-location creates some danger as it can weaken the bonds. Employee Achievement Recognition is a key way to keep it strong.'
      3: 'The biggest success story is the lesson to learn to listen to your employees and tell others what they told you.'

    what_is_new:
      title: 'Bligson, what\'s new'
      subtitle: 'insights - articles - posts - revelations'
      type:
        blog: 'Blog'
      read_story: 'Read story'

  solution:
    label: 'solution'
    labels: 'solutions'
    title: 'The Bligson Solution'
    subtitle: 'Turn ordinary workers into outstanding achievers '
    hero: 'A total solution for Employee Achievement Recognition'

    achievers:
      label: 'achievers'
      title: 'The Achievers'
      subtitle: 'The ones who accomplish'
      intro: 'As humans in general we all like to make a difference in our lives. We like to achieve and we like to be achievers. However although each day seems to have a purpose, so often we can\'t really recollect what we really achieved during a day, month, year, or even lifetime. Especially with service-oriented tasks or digital creations are very transient. That is why achievers need Bligson. We help to continuously collect experiences of anyone who likes to matter in their work, hobby, or any part of life. '
      1: 'Employees'
      2: 'Association Members'
      3: 'Freelancers'
      4: 'Dealers'
      5: 'Team players'
      6: '...'
    companies:
      label: 'companies'
      title: 'Companies & Organizations'
      subtitle: 'Small and Medium sized Enterprises'
      intro: 'Bligson offers a complete achievement recognition and promotion solution for any company or organization. The services and tools you will find, allow you to collect experiences, promote achievements and benefit from all the analytics that come with it.'
      quote: 'We offer Employee Achievement and Human Resource experts the same kind of tools as has been the norm for so many other professions. Indeed, all kind of professionals such as software developers for example, have been using work automation platforms for years. Now it is your time as well!'
      1: 'Human Resource Managers'
      2: 'Internal Communication Managers'
      3: 'Employer Branding Managers'
      4: 'Public Relations Managers'
      5: 'Marketing Managers'
      6: 'Delivery Managers'
      7: 'CEO, CTO, COO, CMO'
      8: '...'

    associations:
      label: 'Associations'
      title: 'Associations'
      subtitle: 'Professional Associations, Non-profit and governmental organizations'
      intro: 'Professional associations that promote and support a group of professionals, for example "Health Care Professional Associations" or "Association for Independent Software Professionals" benefit greatly. After all, you can show you care for the person behind the worker.'
      quote: 'Our members feel more added value now we help them to recollect their daily experiences. We feel we really help to be proud of their profession.'
      1: 'Health Care'
      2: 'Software Developers'
      3: 'Public service workers'
      4: '...'
 
    workflow:
      label: 'process & workflow'
      title: 'The Bligson workflow and framework approach'
      subtitle: 'Your daily Achievement Recognition Workflow'
      hero: 'A complete flow with all the steps leading towards Achievement Recognition'
      steps: 'Tell their story'
      intro: 'Achievement Recognition is an continuously ongoing process. Every workday you need to ask your clients, employees or other team members about their positive experiences. On a monthly basis you then select the best experience to be the basis of an achievement-interview. On its turn, the result of this interview will become the basis of a story. Obviously, a story needs publication, so hence the following step: publish by any media or {0}. Finally you would like to know about satisfaction trends and publication success by audits and statistics.'
      link:
        deliverable: 'deliverable'
      caption: 'Our framework is highly specialized in Employee Achievement. It´s the ideal tool for both independent as corporate EB Managers'
      1: 'Bligsy automatically collects the most positive experience from all your achievers at the end of each day'
      2: 'Bligsy stores the experiences in the app under your Bligson account'
      3: 'Using the app, Achievement coaches mark the best experiences and interview the achiever to learn more'
      4: 'Coaches or specialized copywriters write great stories'
      5: 'The Stories become Achievements with images and video´s from the repository'
      6: 'To promote the Achievements, a Human Resource or Marketing Manager starts a campaign'
      7: 'By choosing a pipeline-template from the library, all tasks on how to create tangible material, are clear. The integrated Kanban board makes planning and executing tasks a breeze.'
      8: 'When all to-do is done, a new article, gallery, video or portfolio can be shown to all'
   
  service:
    label: 'service'
    labels: 'services'
    title: 'Service and Products'

    categories:
      saas:
        label: 'subscriptions'
        title: 'Subscription Services'
      coaching:
        label: 'coaching'
        title: 'Coaching and Achievement interviews'
        intro: 'We offer hands-on {link} service to find the right experiences to turn into achievements. If so desired, we can provide career coaching your employees at he same time. The price per achievement story depends on a few elements. First it depends on the volume, the number of people in your company that participate as achiever. Second, the required expertise is a factor. If your employees work on very complex technology for example, it is logical that your Achievement Coach should understand what they are doing. Lastly the time the coach invests on each achiever increases if you want to offer employees career coaching as well. Please {contact_link} us to see what our coaches can do for you.'
        link:
          tag: 'coaching'
      deliverables:
        label: 'deliverables'
        title: 'Product, Presentation, Deliverable'
        intro: 'Please contact us to agree on what we can do for you to ensure achievements will turn into stories that you can deliver to each of your audiences. Please {contact_link} us to see what {link} we can produce for you.'
        link:
          tag: 'deliverables'
      workshops:
        label: 'workshop'
        title: 'Implementation, Training and Strategy'
      contact_link:
        tag: 'contact'
    # Services
    framework:
      label: 'framework'
      title: 'Bligson Achievement Framework Subscription'
      hero: 'Our online SaaS application, for mobile and desktop'
      intro: 'Our Bligson Achievement Framework is our advanced, online, SaaS platform. You will find all the features and functionality you need to collect, plan, manage and promote achievements.'
      more: 'Employers, associations, dealer channels, basically every type of organisation that depends on a community of great people will benefit from Bligson. We use terminology that is familiar to marketing experts as well as human resource departments. However, our solution is also suited for other managers, executive assistants and so on, thanks to an extensive knowledge base and easy to use marketing-pipeline-templates. We have special tools for freelance agents, psychologist and marketing service agencies.'
      price_per: 'subscription, per month'

    bligsy:
      label: 'bligsy'
      title: 'Bligsy Experience Collection Bot'
      subtitle: 'What happy thing did you achieve today?'
      hero: 'Daily interaction with all your employees and other achievers'
      intro: 'Bligsy is the name of a Telegram Bot we especially developed to collect experiences of ´achievers´. Achievers are usually employees of organizations but can be anybody: dealers, students, association members and so on.'
      more: 'At the end of each work-day, Bligsy will ask each participant to tell in a few words what positive thing they experienced that day. Our Bligson Framework assembles the experiences for further processing into ´achievements´. Therefore, please note that for Bligsy to work you also need the aforementioned subscription to our Framework. '
      slider: 'Maximal number of participating achievers in your organization:'
      price_per: 'for {n} achievers, per month'
      features:
        achievers:
          label: 'Achievers'
          title: 'Unlimited achievers per account'
          subtitle: 'Any number of achievers can connect to an account'
          intro: 'Each achiever can subscribe to an account with a special invitation link. There is no limit to the number of achievers per account. The monthly service fee to use Bligsy depends on the number of achievers you like to service.'
        accounts:
          label: 'Multiple accounts'
          title: 'Multiple accounts per achiever'
          subtitle: 'An achiever can be member of one or more accounts'
          intro: 'An account, such as a company can obviously serve many employees e.g. achievers. On the other hand, an achiever can also share his or her experiences to multiple accounts/companies.'
        # team:
        #   label: 'Bot team'
        #   title: 'Team or group'
        #   subtitle: 'Allow team or group participation'
        #   intro: '...'
        question:
          label: 'Daily question'
          title: 'Daily experience question'
          subtitle: 'Ask them what went right today'
          intro: 'Bligsy will only ask for positive experiences. These experiences can be private or related to work. It is scientifically proven that if you'
        timing:
          label: 'Bot timing'
          title: 'Configure bot timing'
          subtitle: 'Set the right time and moment for Bligsy'
          intro: 'Usually it is best to tell Bligsy to ask the experience question at the end of the afternoon during each workday. Depending on the time-zone of the achievers or different schedules, account owners can configure a different time-schedule.'
        level:
          label: 'Experience level'
          title: 'Grade the experiences'
          subtitle: 'Learn how they feel'
          intro: 'With each new experience of an achiever, Bligsy will ask how good it was. The achiever can answer "ok", "good" or "excellent". This level is in fact a grade that will tell you how the satisfaction of your achievers develop over time. It is essential to know the situation before things get out of hand. '
        feedback:
          label: 'Continuous feedback'
          title: 'Continuous grade development feedback'
          subtitle: 'Gamify the process'
          intro: 'We need to stimulate each achiever to send in their experiences. One way is to really publish their best experiences as remarkable achievements and deliver these to the audience. The daily motivator is however feedback on their satisfaction level as well as an experience score.'
        ai:
          label: 'Artificial Intelligence'
          title: 'Artificial Intelligence experience classification'
          subtitle: 'Automation to find trends and spot remarkable achievements'
          intro: 'Potentially, Bligsy can generate a lot of data. Imagine all your employees providing a few words about their experiences each day. Our framework provides insight in trends and can scan the messages in search of remarkable experiences, the best achievements and the development of happiness of your workers over time…'
       
    coaching:
      label: 'Achievement Coaches'
      title: 'Achievement Awareness Coaches'
      subtitle: 'Listen, understand and tell their story'
      hero: 'Ensure the experiences are heard and the achievements recognized '
      intro: ''
      recognition:
        title: 'Achievement Recognition'
        intro: 'In a large stream of daily experiences, our Achievement Coaches will find the little pearls. The ones that might make a difference in the performance of the company or the employees themselves. We use AI technology to process the lists, check satisfaction trends and just read the experiences to feel what is going in'
      interview:
        title: 'Achievement Interviewing'
        intro: 'Usually online, but if needed onsite, Achievement Coaches will talk to your employees, the achievers. They will interview them to hear why that specific experience matters to them and why they think it makes a difference. The Achievement Coach will write up an interview report after each meeting.'
      coaching:
        title: 'Career Coaching'
        intro: 'When taking the time to listen to your Employees as achievers, it is only a little step to also provide career coaching. This process is based on the trust that our Achievement Coaches will earn from you and the employee in question.'
      story_telling:
        title: 'Story Telling'
        intro: 'The Achievement Coach will write the experiences in a compelling story. Usually one page is enough to give a good impression why the achievement of the employee is remarkable.'

    deliverables:
      label: 'Publishing & Deliverables'
      title: 'Promote the achievement stories: publish & media'
      subtitle: 'Deliver their story'
      hero: 'Ensure the audience truly cares'
      intro: 'Obviously, any story needs to be published to have any impact. Bligson can assist you with the publication in many different forms and by any media. To mention a few:'
      blog_post:
        title: 'Blog Post'
        intro: 'To use an Achievement Story as post in your blog will take very little effort. Depending on the type of achievement, you might want to publish it on your internal corporate blog or on the external site for additional promotion. As such, each achievement story could work as Employer Branding content or regular marketing content.'
      portfolio:
        title: 'Portfolio'
        intro: 'An example of a good use of a portfolio, is given by a health care clinic. In this clinic, they noticed that Nurses contributed remarkably during the pandemic. To recognize these efforts, they decided to set up a portfolio creation process for nurses. Over time, each nurse is building a portfolio to illustrate their personal work and achievements. They intend to present the portfolio to the nurses during their Christmas party, at then of each work-year.'
      museum:
        title: 'Museum and Gallery'
        intro: 'Coolblue is one of the largest e-retailers in Europe. Behind the scenes, it is all about software. Coolblue gives their developers a podium by displaying beautiful pieces of code. These code are the the personal stories of their employees. And now everybody can admire these works of art: online in a “Museum of Code” but also real life, during a visit to a Coolblue store.'
      interview:
        title: 'Video interview'
        intro: 'A perfect way to highlight a story of an Achiever, is to shoot a professional-looking video. We have perfect story-board templates in our Bligson app if you like to do it yourself. We can also take the complete production in our hands so you can be assured of the best results.'

    # Workshops
    ar_implement:
      label: 'implementation'
      title: 'Achievement Recognition Implementation'
      hero: 'First time setup of AR in your company'
      intro: 'The launch of a good AR program in your company is not always trivial. Our AR coaches will make sure we implement the program successfully, especially for you.'
      more: 'Achievement Recognition coaches are certified specialists by Bligson. They can be  work during the implementation period as well as perform daily operations.'
      notes:
        1: 'set up an Achievement Recognition Platform'
        2: 'set up Bligsy - Experience Collection Bot'
        3: 'guiding each step of the employee Achievement Recognition journey'
        4: 'provide training on how to promote Achievements (marketing campaigns, pipelines, etc.)'
        5: 'helping to highlight the purpose of employees based on collected experiences'
      price_per: 'remote sessions & hands-on service'
    workshop_strategy:
      label: 'strategy'
      title: 'Workshop: Achievement Recognition Strategy'
      hero: 'Top-level strategy development dedicated to your unique company'
      intro: 'Management of Human Resources or Internal Marketing departments, will greatly benefit from a good Achievement Recognition Strategy. To determine this strategy, our most senior experts will work onsite together with your company.'
      more: ' (to do AK )...'
      price_per: 'on-location workshop'
    workshop_coach:
      label: 'coach training'
      title: 'Achievement Coach Program'
      hero: 'Learn how to help others by running their AR implementation'
      intro: 'Achievement Coaches know how to implement AR and how to talk to employees about their experiences. We have a special training program for freelancers of agencies to become AR coach.'
      more: 'By participating in our special trainings program, AR coaches will learn to understand the pitfalls in organizations and they know how to deal with anxiety many people have in their daily jobs. (to do AK )...'
      price_per: 'two online sessions'

  feature_group:
    label: 'Features'
    title: 'Framework Features'
    achievements:
      label: 'achievements'
      title: 'Achievement Assembly'
      subtitle: 'Capturing their best'
      intro: 'ALL employee recognition begins with the collecting of their achievements. The Bligson Framework offers you all the features you need to capture these precious moments. In addition, specialists can offer you hands-on services to get the work done.'
    planning:
      label: 'planning'
      title: 'Project Planning and Progress'
      subtitle: 'Do what`s needed and in time'
      intro: 'Plan, organize & keep track of your Employee Achievement process. While you might be tempted to use the same tool other employees such as software developers use in your company, you will find that the special focus we give Employee Achievement will actively help you to establish the specialism.'
    repository:
      label: 'repository'
      title: 'Repository'
      subtitle: 'A picture says more than many words'
      intro: 'A repository is the database with all assets or media, such as images or videos you like to attach to the achievement stories. You can share the media with third parties such as your marketing agencies. In addition, the media is available on your blogs or other websites.'
    authorization:
      label: 'authorization'
      title: 'User roles, notifications and authorization'
      subtitle: 'Approval and continuity'
      intro: 'Just like with any other Public Relation activity, it is essential you tell not too little and not too much. We introduced authorization steps to notify managers to enable them to sign off a certain story. In this way you maximise progress while still guarding trade secrets or other confidential information. In addition, when you work with multiple users or independent with clients, user roles help you to determine who can do what.'
    publishing:
      label: 'publishing'
      title: 'Publishing: Delivering in various forms and media'
      subtitle: 'Getting it out there'
      intro: 'The achievement story is for you, what content is for your colleagues in the marketing department. And you use this content for a wide range of publications: from YouTube clips to Corporate Presentations or Portfolio binders. We offer a large database with workflow templates that include tasks and checklists, so you do not need to reinvent the wheel.'
    learning:
      label: 'learning'
      title: 'Study and Knowledge base'
      subtitle: 'Tips, tricks, study and knowledge'
      intro: 'We suggest two types of learning processes: objectively and subjectively. You want to learn from real data, and you like to learn from perceived quality. We offer the feedback tools you need in your Employee Achievement campaigns.'
    integrations:
      label: 'integrations'
      title: 'Software Integrations'
      subtitle: 'Connect your software'
      intro: 'Our custom integrations allow you to retrieve the major story data from the Bligson app. You can for example use this data to create a portfolio page for your own staff on your one intranet or website. With tag-filtering you can limit the information to a certain sub-set'

    experiences:
      label: 'experiences'
      title: 'Experience Collecting'
      subtitle: 'Keep showing them interest'
      intro: '... to do AK.'

  feature:
    label: 'feature'
    labels: 'features'

    # Person related
    accounts:
      label: 'accounts'
      title: 'Accounts'
      subtitle: 'Each client is different'
      intro: 'An account is like a company or a client. In Bligson Accounts are the place where to store all information related to one company or department. So it will contains all marketing related information such as campaigns, stories, pipeliens and tasks. If you like to manage multiple accounts, you can do so by taking multiple subscriptions. To use Bligson you need to create at least one account. You usually choose an account per company you are working for, but if the company has multiple divisions, you might want to choose one account for each.'
    users:
      label: 'users'
      title: 'Users'
      subtitle: 'Who can see and do what'
      intro: 'Every individual joining Bligson is a user. You are not allowed to share one user amongst different people. Users can have a certain role in an account. They can be owner, member or regular user. Owners can do everything such as create and delete, managers can edit and regular users can just view. Every account should have at least one system user. This is a person that is in charge of controlling the app. A user can be owner, manager or regular member. An owner can do all, a member just read. Each account should have at least one owner. Users can be member of more than one account.'
    specialists:
      label: 'specialists'
      title: 'Specialists'
      subtitle: 'Let the best help you'
      intro: 'Users that like to offer their services to other users, can apply to us for specialist status. Specialists appear in the account management pane and users can invite them to take part in the service for an account.'
    achievers:
      label: 'achievers'
      title: 'Achiever experiences'
      subtitle: 'Interface to Bligsy to store the daily experiences of achievers'
      intro: 'By asking your employees or members every work day, to tell in a couple of words, what positive achievement they experienced that day, you will have a great start for the Achievement Recognition processing. This feature requires Bligsy, our Happy Bot to assemble these experiences.'
    authorizations:
      label: 'authorization'
      title: 'Authorization'
      subtitle: 'Introduce workflows with go/no go decisions'
      intro: 'In larger organizations or working with external agencies, it might be needed to ask certain managers to authorize an action before continuation.'
    notifications:
      label: 'notifications'
      title: 'Notifications'
      subtitle: 'Push notifications using Telegram and Email'
      intro: 'Most actions in the system result in a notification. You can have your notifications sent to owners or managers such they know what is when happening. At present Bligson focusses on using Telegram and or email to send out push notifications.'
    consultancy:
      label: 'consultancy'
      title: 'Achievement coaches'
      subtitle: 'The responsibility to get it done'
      intro: 'To collect achievements, one should be able to first recognize them and second write up a compelling story. This requires deep knowledge from the specific domain as well as the ability to present. For our selected industries, Bligson can direct you to coaches that assemble a specific number of achievements per week and write them into compelling stories.'
    mentorship:
      label: 'mentorship'
      title: 'Mentorship'
      subtitle: 'Active guidance'
      intro: 'A mentorship is a relationship between two people where the more experienced individual is able to pass her knowledge along. Especially during the achievement consultancy interviews, there is usually a great opportunity for mentor support by experienced coaches.'

     # Planning
    calendar:
      label: 'calendar'
      title: 'Calendar'
      subtitle: 'When is what?'
      intro: 'A comprehensive calendar shows all your campaigns and pipelines with start and end dates.'
    stories:
      label: 'stories'
      title: 'Stories'
      subtitle: 'Words to capture emotions'
      intro: 'A beautiful, minimalistic editor allows you to concentrate on writing. You can include Grammerly for checking on grammar and spelling. You can insert images or videos from the repository to illustrate what you have to tell.'
    campaigns:
      label: 'campaigns'
      title: 'Campaigns'
      subtitle: 'Leading the way with comprehensive strategy'
      intro: 'A campaign is all about the GOAL you like to achieve. Climb a mountain, become a leading expert, anything that fits your marketing strategy. Unlike Pipelines, Campaigns usually do not lead to tangible results, but are more about a desired position.'
    dashboard:
      label: 'dashboard'
      title: 'Dashboard'
      subtitle: 'Your homepage'
      intro: 'You can configure your Dashboard with widgets. Widgets display any kind of information that you find useful, so that each time you start Bligson you immediately see what is going on.'
    pipelines:
      label: 'pipelines'
      title: 'Pipelines'
      subtitle: 'Controlling the chaos with Tactical Implementation'
      intro: 'Pipelines should describe tangible results. If you can see or hear it, you can manage it\'s creation with a pipeline. Pipelines describe exactly about how you want to go there. The contain all the tasks you would need perform to get to the best visible results. '
    tasks:
      label: 'tasks'
      title: 'Tasks'
      subtitle: 'Get things done'
      intro: 'The tasks are your actual ´to-do´ items. Tasks can contain descriptions, can be assigned to users and can have a checklist. We group tasks in 5 stages: strategy, content, verify, publish and interaction. A task status can be planned, ongoing or finished.'
    'time-trackers':
      label: 'timers'
      title: 'Time trackers'
      subtitle: 'Where is time going?'
      intro: 'Whenever you start to use Bligson to work on your Employee Achievement campaigns, you can start a timer. After you stop the timer when you are finished, Bligson will save your timeslot in an overview. Ideal for independent Employee Achievement consultants or larger organizations that like to know how much time we spend on each achievement.'
    'trend-watching':
      label: 'trends'
      title: 'Trend watching'
      subtitle: 'Increasing or decreasing happy achievements?'
      intro: 'Based on the content and frequency of the experiences our bot, Bligsy, collects each day from your employees, our artificial intelligent enhanced software can inform you if your employees are happy or maybe if they are about to leave…'
    'pipeline-templates':
      label: 'Templates'
      title: 'Pipeline templates'
      subtitle: 'Do not reinvent the wheel'
      intro: 'To increase commercial success, it is worthwhile to increase efficiency, performance, and agility in the day-to-day operations of your marketing communications process. Our workflow solutions support this this process with various services. We offer templates for workflows to get you started real quickly.'
    # Repository
    experiences:
      label: 'experiences'
      title: 'Experiences'
      subtitle: 'The content basis for all recognitions'
      intro: 'Bligson offers a database to save all experiences that Bligsy collects for you. You can link a story to an individual employee or a team. With tagging and labelling you can plan, filter and search.'
    assets:
      label: 'assets'
      title: 'Assets'
      subtitle: 'A picture says more than many words'
      intro: 'A repository is the database with all assets or media, such as images or videos you like to attach to the achievement stories. You can share the media with third parties such as your marketing agencies. In addition, the media is available on your blogs or other websites.'
    # Publishing
    results:
      label: 'results'
      title: 'Results: media'
      subtitle: 'It`s the output that matters'
      intro: ''
    # Learning
    information:
      label: 'information'
      title: 'Information knowledge base'
      subtitle: 'Learning while doing'
      intro: 'A knowledge base is a self-serve online library of information about a product, service, department, or topic. The data in our knowledge base can come from anywhere. Typically, contributors who are well versed in the relevant subjects add to and expand the knowledge base. The content can range from the ins and outs of your EB Manager, HR or legal department to an explanation of how a product works. The knowledge base may include FAQs, manuals, troubleshooting guides, runbooks, and other information your team may want or need to know.'
    audits:
      label: 'audits'
      title: 'Audits'
      subtitle: 'Observation of the results'
      intro: 'An audit is an "independent examination of information of any entity. Auditing attempts to ensure that the content is properly created and maintained according to best practices. Audits provide third-party assurance to various stakeholders. Bligson provides tools to audit every task in a pipeline so that the average or all audit-tests will give a grade than you can use to compare the quality of the EB marketing material you create.'
    analytics:
      label: 'analytics'
      title: 'Analytics'
      subtitle: 'Feedback, learning from your own data'
      intro: 'Web analytics is a process of collecting, analyzing as well as reporting of data to understand and optimize web usage. Bligson can provide you with additional insight in the usage and metrics of the employee achievement stories.'
    # API
    api:
      label: 'api'
      title: 'API'
      subtitle: 'Application Program Interface'
      intro: 'Bligson´s API is both simple to understand and to use. It also has powerful features that allow you to configure exactly what data which of your applications can retrieve and when.'
    custom_development:
      label: 'development'
      title: 'Custom Development'
      subtitle: 'Adapt your software'
      intro: 'Our software development partners have experience in using our API to update your website. No matter if you created your site with Wordpress or another technology or CMS, we should be able to find the right partner in our official partner network.'
    #Workshop
    workshop_setup:
      label: 'setup workshop'
      title: 'Practical setting up Workshop'
      subtitle: 'Get it going'
      intro: 'The goal of the workshop is to set up the Achievement Recognition Platform and Bligsy (Experience Collection Bot). A dedicated coach will tell you what to do and how to do it – you will practice to set up with your hands. At the end of the workshop, you fell have ready to run Framework and Bligsy. The duration of the workshop is 4 -5 hours.'

  pricing:
    label: 'pricing'
    title: 'Offering & Pricing'
    subtitle: 'scalable services, comprehensible support'
    intro: 'Signup in a minute; upgrade, downgrade or cancel at any time.'
    available:
      now: 'Offered features'
      expected: "Expected available functionality {a}"
    sign_up: 'Sign up'
    free_trial: 'Free 14 day Trial'
    only_email: "You only need your email to sign-up; we promise to never spam you!"
  
    payment:
      term:
        label: 'pay'
        yearly: 'year'
        quarterly: 'quarter'
        monthly: 'month'
        save: 'to save {d}%'
      condition:
        fluctuate: 'Prices can fluctuate with currencies.'
        ex_VAT: 'Prices exclude local VAT and other taxes and without travel / staying costs if applicable.'
        cancel: 'Cancellation/reduction of subscriptions is possible until ten work-days before the start of each new month.'

  industry:
    label: 'Industries'
    title: 'Industries'
    subtitle: 'Different business, same needs'
    intro: 'The tremendous amount of noise the society creates these days is leaving the individual gasping for `air`. Therefore we believe that today all workers should benefit from Achievement Recognition Support.'
    information_technology:
      label: 'Information Technology'
      title: 'Information Technology, Software & Services'
      subtitle: 'Software developers, outsourcing, IT departments'
      quote: 'The tech talent war has no end in sight.'
      link:
        href: 'https://www.forbes.com/sites/larryenglish/2021/06/01/the-tech-talent-war-has-no-end-in-sight-heres-what-you-need-to-know/'
        text: 'Forbes'
      intro: 'While the shortage is already lasting for decades, Covid severely exacerbated the skills gap. Now over 40% of employees are considering making a job change. It`s time you make your employees truly happy. An no, we are not talking about table foosball or free donuts. Instead we encourage to truly recognize their actual achievements.'
    health_care:
      label: 'Health & Care'
      title: 'Pharmacy, Health Care'
      subtitle: 'Hospitals, clinics, suppliers, education'
      quote: 'Nursing is in crisis\': Staff shortages put patients at risk.'
      link:
        href: 'https://www.nytimes.com/2021/08/21/health/covid-nursing-shortage-delta.html'
        text: 'New York Times'
      intro: 'Unlike other industries, health-care does not have the luxury to `buy` employees with higher wages. Additional emphasis on recognition of achievements and reliability as an employer is crucial. Even more so as burnout is both a cause and symptom of the nursing shortage.'
    manufacturing:
      label: 'Manufacturing'
      title: 'Manufacturing, Industry & Engineering'
      subtitle: 'Industry: where things are made'
      quote: 'Wage gains at factories fall behind growth in Fast Food'
      link:
        href: 'https://www.wsj.com/articles/wage-gains-at-factories-fall-behind-growth-in-fast-food-11624354200'
        text: 'Wall Street Journal'
      intro: 'Factories have historically offered better wages than restaurants and retail companies. They still pay workers much higher wages on average, The Journal reported, but fast-food chains are now raising their wages at a much faster rate. Basically, manufacturing is not living on an island and needs to step up it`s turf.'
    construction:
      label: 'construction'
      title: 'Building, construction'
      subtitle: 'Offshore industry, Housing, Roads…'
      quote: 'Home prices will keep soaring through 2023 as construction will fail to meet demand, study says'
      link:
        href: 'https://www.businessinsider.com/housing-market-outlook-home-price-inflation-construction-supply-shortage-building-2021-5'
        text: 'Business Insider'
      intro: 'There is a labor shortage in the construction industry both in America as well as in Europe. This has led to more than a third of contractors reporting that they\'ve turned down work due to a lack of workers. More than ever before, make sure your workers are truly proud of their work - every day.'
    transport:
      label: 'Transport & Logistics'
      title: 'Transport & Logistics'
      subtitle: 'At the right moment, at the right place'
      quote: 'UK plunges towards supply chain crisis due to staff and transport disruption'
      link:
        href: 'https://www.theguardian.com/business/2021/aug/24/uk-retailers-stock-supply-shortages-covid-pingdemic'
        text: 'The Guardian'
      intro: 'Factors such as an aging workforce and insufficient numbers of new recruits, due to working conditions and image issues of the profession, have been plaguing the industry for many years. '
    food:
      label: 'Food & Hospitality'
      title: 'Restaurants, Food & Hospitality'
      subtitle: 'Where did they go?'
      quote: '‘It\'s a minefield\': US restaurant workers leave industry over Covid'
      link:
        href: 'https://www.theguardian.com/us-news/2021/may/14/us-restaurant-workers-covid-pandemic-labor-shortages'
        text: 'The Guardian'
      intro: 'Workers cite exploitative practices and lack of Covid safety protections amongst the reasons why they do not want to return to work in restaurants. If you call your staff essential, then you should not treat them like they\'re disposable '

  company:
    label: 'Company'
    intro: 'Bligson is global leader in Achievement Recognition solutions. We offer the complete Workflow from collecting experiences to promoting achievements. In addition, we provide management with a continuous insight in the actual happiness of their employees.'
    
    # compelling: 'Modern organizations that need to recruit & keep the best employees use Bligson to plan and execute world-class employer branding strategies.'
    about:
      label: 'about'
      title: 'About us'
      subtitle: 'Make them shine'
      intro: 'So often, the most valuable employees get the least credit. They make their hands dirty and don´t brag. But make no mistake: they do know when they are not heard. And they are frustrated when they feel they do not really matter. '
      
    vision:
      title: 'Our vision'
      primary: 'Happiness is a continuous flow of recognized achievements'
      secondary: ''
      intro: 'We think that employees will feel proud and heard when you will capture and assemble their most important achievements.'
    mission:
      title: 'Our mission'
      compelling: 'Our mission is to help organizations to continuously recognize the most compelling achievements of their employees.'
      statement: 'We aim to provide all the expertise, tools and artificial intelligence that can help organizations to recognize, collect and store the achievements of the workers that deserve it the most. To assist in telling their stories by any media and opportunity and by this rewarding existing employees, attract the best new candidates and provide extensive insight to the management.'
      1:
        title: 'Specialized tools'
        intro: 'Unlike ordinary project planning applications, which lack dedicated employee achievement recognition expertise, our online framework is specifically designed for workers and their stories.'
      2:
        title: 'Do it for them'
        intro: 'We facilitate collecting employee\'s {0}, {1} and {2} in any type of {3}.'
        link:
          st: 'stories'
          in: 'insights'
          am: 'achievements'
          ind: 'industry'
      3:
        title: 'Benefit from experience'
        intro: 'A library of {0} and {1} help you to create top-quality blogs, videos, white-papers, newsletters, presentations, recruitment events and {2}.'
        link:
          wf: 'workflows'
          cl: 'checklists'
          mr: 'dozens more'
    how:
      label: 'How we work'
      title: 'How we work'
      subtitle: 'Enjoying creativity and dedication'
    team:
      label: 'Our Team'
      title: 'Meet the people'
      subtitle: 'Dedication & serious fun'
      management:
        title: 'Management & Advisory Board'
        members: 'tom_voorneveld|gena_serdyuk'
        tom_voorneveld:
          name: 'Tom Voorneveld'
          title: 'founder & executive advisor'
          intro: 'With more than 25 years of experience in IT, software development, mobile technology, marketing and sales, Tom has earned his stripes. He has degrees in Business Administration and Technical Computer Science. In addition to leading Bligson, Tom is also partner at <a href="https://www.lorinthe.com" target="_blank">Lorinthe</a>, an executive advisory agency. <a href="https://www.linkedin.com/in/tom-voorneveld/" target="_blank">LinkedIn</a>'
        gena_serdyuk:
          name: 'Gennadiy Serdyuk'
          title: 'technical advisor'
          intro: 'Gena has almost 30 years of experience in software development and electronics design. His specialization in connected electronics makes him a great partner for electronics companies, intercommunication and the "Internet of Things". <a href="https://www.linkedin.com/in/gserdyuk/"  target="_blank">LinkedIn</a>'
        martin_lopez_nores:
          name: 'Martín Lopez Nores'
          title: 'linguistic advisor'
          intro: 'Martín is leading Researcher from University of Vigo and CROSSCULT H2020 Project. His specialties include: interactive information services, semantic reasoning and interactive storytelling. As member of the editorial team of Scientific Research and Essays, he contributes to Bligson\'s linguistic foundation.'
        # olga_kotlamina:
        #   name: 'Olga Kotlamina'
        #   title: 'project management advisor'
        #   intro: 'Olga has more than 15 years of experience in software development: leading international projects, managing and improving business processes, project leadership. Olga bridges the gap between business and technology. Certified Scrum Master, Product Owner, Agile growth coach.'
    
    job:
      label: 'Jobs'
      title: 'Join Bligson !'
      subtitle: 'Make a real difference out there'
      intro: 'Our customers entrust us with the responsibility of helping to run their communication channels. We work tirelessly to be worthy of that trust. It is rewarding work, as the end results are visible and highly appreciated.<br><br>Please feel welcome to contact us!'
      contact: 'Apply now'

    faq:
      label: "FAQ"
      title: 'Frequently Asked Questions'
      subtitle: 'All you need to know and more'
      intro: 'Feel welcome to review the following often asked questions. If you have any remaining questions please feel free to contact us.'
      contact: 'Contact'
    contact:
      label: 'Contact'
    terms:
      label: 'Terms & Privacy'
      title: 'Terms, Conditions and Privacy'
      subtitle: 'The small print'
      # intro: 'The following terms and conditions ("Terms of Use"), govern your access to and use of our Bligson application'

  partners:
    label: 'Partners'
    title: 'Partners & Service Providers'
    subtitle: 'Join Team Bligson'
    hero: 'Partner success is a top priority at Bligson. Our global partners not only enable us to deliver superior value to our customers by offering value-add solutions; they also help grow our business in specific vertical markets.'
    intro: 'Employee Achievement Recognition is an exciting new Human Resource and Marketing field. Especially freelancers or agencies in this field can significantly increase their turn-over and profits. From day one, we developed our tools to support multiple accounts. This ideally suit partners, which we call "Achievement coaches", who have more than one client. Especially our Bligson Framework is an advanced application that we designed to use by specialists. So, please contact us as we welcome our partners to take the lead.'
    assembly:
      title: 'Achievement Assembly'
      punchline: 'from experience to achievement to story'
      intro: 'While Bligsy will do the tedious work to daily ask the team what good they experienced that day, this is only the beginning of the process. Now experts need to scout the experiences and find the best. You would then interview the team member. You will ask them all you need so you can write their story and find supporting visual material.'
      tasks:
        title: 'Tasks'
        1: 'Turn team-members such as the employees of clients into achievers'
        2: 'Mentor and stimulate achievers to submit their daily positive experiences'
        3: 'Assess the best experiences as achievement candidates'
        4: 'Interview the team member'
        5: 'Have in-depth knowledge of their area of expertise'
        6: 'Write their story'
        7: 'Assemble visual material to support the story'
        8: 'Deliver feedback to the management of the client'
      requirements:
        title: 'Requirements'
        1: 'Very strong in communication, native language'
        2: 'Trustworthy'
        3: 'Positive'
        4: 'Understanding of marketing principles'
        5: 'Copywriting expertise'
      contact: 'Please {0} if you like to become a Bligson Achievement coach.'
    delivery:
      title: 'Achievement Marketing & Promotion'
      punchline: 'from story to media & promotion'
      intro: 'An achievement story is nothing without delivery in the right format to the right audience. Achievement coaches can offer their clients to turn the stories into media; tangible material. Even relatively unexperienced partners can be very effective as Bligson offers a smart set of services supporting the process. For example, we offer an extensive set of marketing automation workflows for delivery to blog-posts, YouTubes, art galleries and dozens more. These workflows are campaigns and pipelines with pre-defined cards. These cards outline each task you need to finish for the best result. In addition, we have a knowledge base and offer training and support.'
      tasks:
        title: 'Tasks'
        1: 'Run campaigns and media-creation pipelines for your client'
        2: 'Manage assets such as images'
        3: 'Create promotion material, online and offline'
        4: 'Deliver material to the right target audience'
      requirements:
        title: 'Suitable partners'
        1: 'marketing agencies'
        2: 'freelance marketeers'
        3: 'PR specialists'
        4: 'Employer Brand managers'
      contact: 'We are always looking for new Achievement Delivery Partners for cooperation in this exciting new world. Please {0} and apply.'

  # audit:
  #   label: 'Audit'
  #   title: 'Blog Page Audit'
  #   subtitle: 'Investigation of your blog page'
  #   results:
  #     findings: 'Quick scan report'
  #     score:
  #       your: 'your score'
  #       industry: '"{i}" - average industry score'
  #     level:
  #       title: 'Explaining the scores'
  #       red: 'Poor; most likely you are loosing business'
  #       orange: 'Fair; you are advised to improve'
  #       blue: 'Good; however improvements still recommended'
  #       green: 'Excellent!'
  #     website:
  #       label: 'Website / blog page scanned'
  #     email:
  #       label: 'Email of requester'
  #     score_of:
  #       label: 'Blogging grade'
  #       result: 'Score of {t}% based on {c} criteria'
  #   industry:
  #     select: 'Comparative Industry'
  #     empty: 'please select comparative industry'
  #   mail:
  #     subject: 'The Bligson audit results of your website '
  #     body: 'You will find the report at:\n\n'
  #   scan: 'Scan criteria and details'
  #   criteria:
  #     label: 'Criteria'
  #     admin: 'admin'
  #     not_relevant: 'Not assessed'
  #     factor: 'factor={f}'

  #   # The TESTS
  #   group:
  #     home: 'Home page checks'
  #     blog: 'Blog/News listing checks'
  #     post: 'Post/Article checks'
  #   subgroup:
  #     basics: 'Website Basics'
  #     impress: 'General Post Impression'
  #     listing: 'Listing Page Basics'
  #     content: 'Content and quality questions'
  #     expertise: 'Expertise questions'
  #     presentation: 'Presentation and production questions'
  #     comparative: 'Comparative questions'

  #   test:
  #     # For home-group, website general
  #     blog_top_menu:
  #       title: 'Blog in top menu?'
  #       intro: 'Is there are clearly visible blog-type: "blog", "insights" or "articles", menu item in the top bar or main menu?'
  #     news_top_menu:
  #       title: 'News in top menu?'
  #       intro: 'Is there are clearly visible news-type menu item in the top bar or main menu?'
  #     blog_bot_menu:
  #       title: 'Blog in bottom menu / bar?'
  #       intro: 'Is there are reasonably visible blog-type menu item in the status bar or footer menu?'
  #     news_bot_menu:
  #       title: 'News in bottom menu / bar?'
  #       intro: 'Is there are reasonably visible news-type menu item in the status bar or footer menu?'
  #     secure_connect:
  #       title: 'Secure & valid certificate?'
  #       intro: 'Does the website uses a secure https:// connection with a valid certificate?'
  #     google_light:
  #       title: 'What is the Google Lighthouse score?'
  #       intro: 'Did the website appear fast on first page load? Google developed a purely objective tool to measure the time webpages take to become visible.'

  #     # For blog-group: for listing / overview
  #     any_date:
  #       title: 'Creation date visible?'
  #       intro: 'Dated blog or news posts generate more trust'
  #     author_name:
  #       title: 'Author name visible?'
  #       intro: 'Showing a real person wrote the article, and not some automated bot, will generate more loyalty.'
  #     author_back:
  #       title: 'Author background info?'
  #       intro: 'Besides the name of the author it is also good practice to provide a link to a profile. Readers can learn more about their expertise.'
  #     blog_frequency:
  #       title: 'Blog frequency'
  #       intro: 'A good average nr of posts per year over the last two years gives users more confidence. We consider an average of two per month for a maximal score.'
  #     listing_image:
  #       title: 'Images in listing?'
  #       intro: 'Images make a listing a lot more attractive'
  #     reading_time:
  #       title: 'Visible "time to read" in minutes?'
  #       intro: 'Users are more likely to read an article if you show how much time it would generally take them.'
  #     user_scores:
  #       title: 'Objective user scores'
  #       intro: 'Stars or other reward points from readers make an article more trustworthy.'

  #     # For post-group for Each Blog Post

  #     sharing_butt:
  #       title: 'Social media sharing buttons?'
  #       intro: 'Are there social media sharing buttons to share the article?'
  #     profile_links:
  #       title: 'Profile links'
  #       intro: 'Readers can find at least one social media link to profiles'
  #     call_to_action:
  #       title: 'Call to Action buttons?'
  #       intro: 'What about the availability of specific "call-to-action" buttons?'
  #     abbreviations:
  #       title: 'Not too abbreviations?'
  #       intro: 'Even amongst experts, an over-use of abbreviations make an article less interesting to read'
  #     blog_size:
  #       title: 'Blog size'
  #       intro: 'Usually a small blog post keeps a reader less focussed on what you have to tell. We found that at least 1000  ~ 1 page A4 is desired. 0-500 words would be small, 500-1000 ok and 1000 + words good.'

  #     original_info:
  #       title: 'Original information?'
  #       intro: 'Does the content provide original information, reporting, research or analysis?'
  #     topic_descript:
  #       title: 'Topic well described?'
  #       intro: 'Does the content provide a substantial, complete or comprehensive description of the topic?'
  #     insightful:
  #       title: 'Insightful?'
  #       intro: 'Does the content provide insightful analysis or interesting information that is beyond obvious?'
  #     added_or_copy:
  #       title: 'Added value on copied material?'
  #       intro: 'If the content draws on other sources, does it avoid simply copying or rewriting those sources and instead provide substantial additional value and originality?'
  #     good_headline:
  #       title: 'Good headline?'
  #       intro: 'Does the headline and/or page title provide a descriptive, helpful summary of the content?'
  #     moderate_head:
  #       title: 'Moderate non-shocking headline?'
  #       intro: 'Does the headline and/or page title avoid being exaggerating or shocking in nature?'
  #     bookmark:
  #       title: 'Bookmarkable?'
  #       intro: 'Is this the sort of page you\'d want to bookmark, share with a friend, or recommend?'
  #     printed_worthy:
  #       title: 'Worthy of printed magazines?'
  #       intro: 'Would you expect to see this content in or referenced by a printed magazine, encyclopedia or book?'

  #     trustworthy:
  #       title: 'Trustworthy?'
  #       intro: 'Does the content present information in a way that makes you want to trust it, such as clear sourcing, evidence of the expertise involved, background about the author or the site that publishes it, such as through links to an author page or a site\'s About page?'
  #     rec_authority:
  #       title: 'Well recognized authority?'
  #       intro: 'If you researched the site producing the content, would you come away with an impression that it is well-trusted or widely-recognized as an authority on its topic?'
  #     expert_write:
  #       title: 'Expert written?'
  #       intro: 'Is this content written by an expert or enthusiast who demonstrably knows the topic well?'
  #     no_fact_error:
  #       title: 'No factual errors?'
  #       intro: 'Is the content free from easily-verified factual errors?'
  #     money_worth:
  #       title: 'Would you put your money on it?'
  #       intro: 'Would you feel comfortable trusting this content for issues relating to your money or your life?'

  #     good_spelling:
  #       title: 'No obvious spelling mistakes?'
  #       intro: 'Is the content free from spelling or stylistic issues?'
  #     structured:
  #       title: 'Structured and nice appearance?'
  #       intro: 'Was the content produced well, or does it appear sloppy or hastily produced?'
  #     not_mass_prod:
  #       title: 'Not mass produced?'
  #       intro: 'Is the content mass-produced by or outsourced to a large number of creators, or spread across a large network of sites, so that individual pages or sites don\'t get as much attention or care?'
  #     moderate_ads:
  #       title: 'Not too many ads?'
  #       intro: 'Does the content have an excessive amount of ads that distract from or interfere with the main content?'
  #     nice_mobile_vw:
  #       title: 'Nicely formatted on mobile?'
  #       intro: 'Does content display well for mobile devices when viewed on them?'

  #     standing_out:
  #       title: 'Standing out of the search-crowd?'
  #       intro: 'Does the content provide substantial value when compared to other pages in search results?'
  #     real_interest:
  #       title: 'Serve real interest?'
  #       intro: 'Does the content seem to be serving the genuine interests of visitors to the site or does it seem to exist solely by someone attempting to guess what might rank well in search engines?'

  contact:
    label: 'Contact'
    title: 'Contact our team'
    subtitle: 'happy to answer your questions'
    field:
      intro: 'Please fill out the form'
      first_name:
        label: 'First name'
        placeholder: 'Jean'
      last_name:
        label: 'Last name'
        placeholder: 'Johnson'
      work_email:
        label: 'Work email'
        placeholder: 'jean@mycompany.com'
      company:
        label: 'Company'
        placeholder: 'MyCompany Ltd.'
      company_size:
        label: 'Company size'
        options: '1-10|11-100|101-1000|1000+'
      country:
        label: 'Country'
        placeholder: ''
      topic:
        label: 'How can we help you?'
        options: 'I want to evaluate Bligson for my company|I am looking for press information|Interested in a career at Bligson|I look for possible cooperation as Bligson Publishing Partner|Interested to become an Achievement Recognition Coach|Other'
      message:
        label: 'Anything else? '
        placeholder: 'What story-telling challenges do you have?'
        hint: 'If you like, you can also send us an email at moreinfo@bligson.com'
    select:
      one: 'Please select one'
    valid:
      required: 'is required'
      not: 'must be valid'
      too_long: 'too many characters'
    sent:
      ok: 'Message sent. You can also send us an email: '
      error: 'Message Failed! Please try again or send us an email: '

  blog:
    label: 'Insights'
    showcase: 'Showcase'
    showing: 'Interesting cases of Bligson clients or Achievement Recognition cases'
    title: 'Bligson Blog'
    subtitle: 'Happily sharing our insights'
    reading_time: 'min{s} read'
    will_follow: 'More information will follow…'
    more_in: 'More in "{s}"…'
    stay_up_to_date: 'Follow us on {0} or {1} and receive notifications on new posts.'
    tags:
      all: 'all'
      marketing: 'Marketing'
      blogging: 'Blogging'
      sales: 'Sales'
      services: 'Bligson Services'
      strategy: 'Strategy'

  error:
    title: 'Oops...'
    subtitle: 'We are sorry'
    page_not_found: '404 Page not found'
    other: 'An error occurred'

  def:
    more: 'more…'
    read: 'read more…'
    share: 'Share'
    contact_us: 'contact us'
    ok: 'ok'
    done: 'done'
    error: 'error'
    sorry: 'We are sorry.'
    come_back: 'Please come back later'
    close: 'close'
    zoom: 'zoom'
    submit: 'submit'
    save: 'save'
    cancel: 'cancel'
    send: 'send'
    # time and periods
    year: 'year'
    quarter: 'quarter'
    month: 'month'
    years: 'years'
    quarters: 'quarters'
    months: 'months'
    yearly: 'yearly'
    quarterly: 'quarterly'
    monthly: 'monthly'
