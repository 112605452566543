<template>
  <v-menu top close-on-content-click>
    <template v-slot:activator="{ on }">
      <a :class="selectorClass" class="link" v-on="on">
        <template v-if="responsive">
          <span class="d-flex d-sm-none">{{ currentLocale }}</span><!-- small screens -->
          <span class="d-none d-sm-flex">{{ currentLocaleName.toLowerCase() }}</span>
        </template>
        <span v-else>{{ currentLocaleName.toLowerCase() }}</span>
      </a>
    </template>

    <v-list>
      <v-list-item
        v-for="locale in availableLocales"
        :key="locale.code"
        :disabled="locale.code === currentLocale"
        @click="setLocale(locale.code)"
      >{{ locale.name.toLowerCase() }}</v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  props: {
    // show full size currency on *SMALLER* screens as well?
    responsive: {
      type: Boolean,
      default: false
    },
    selectorClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    availableLocales () {
      return this.$i18n.locales
      // return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    currentLocaleName () {
      const currLocale = this.$i18n.locales.filter(i => i.code === this.$i18n.locale)
      return currLocale[0].name ? currLocale[0].name : this.currentLocale
    }
  },
  methods: {
    setLocale (locale) {
      this.$i18n.setLocaleCookie(locale)
      // this.$i18n.locale = locale
      // this.$root.$i18n.locale = locale
      this.$router.go()
    }
  }
}
</script>
