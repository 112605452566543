 <!--

Show tooltip with darker background and already taking care of the v-on

Binds all the v-tooltip props

Note: disabled on mobile unless show-mobile prop

Example

  <c-tooltip :max-width="300" show-mobile>
    <template #act>
      <v-btn>hover me</v-btn>
    </template>
    This shows up in the tooltip
  </c-tooltip>

-->

<template>
  <div>
    <v-tooltip
      v-if="!disabled"
      :open-delay="openDelay"
      :top="top"
      v-bind="$attrs"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-hover v-slot:default="{ hover }">
            <div :class="{'grey lighten-4': hover && highlightHover }">
              <slot name="act" />
            </div>
          </v-hover>
        </div>
      </template>

      <v-card dark :max-width="maxWidth" class="mx-n4 my-n1 black">
        <v-card-text>
          <slot />
        </v-card-text>
      </v-card>
    </v-tooltip>

    <slot v-else name="act" />

  </div>
</template>

<script>
export default {

  props: {
    top: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: [Number, String],
      default: 540
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlightHover: {
      type: Boolean,
      default: false
    },
    // if false, hide on mobile
    // showMobile: {
    //   type: Boolean,
    //   default: false
    // },
    openDelay: {
      type: Number,
      default: 500 // 500 ms delay until tooltip opens
    }
  }
}
</script>
