<template>

  <v-menu top close-on-content-click>
    <template v-slot:activator="{ on }">

      <a :class="selectorClass" class="link" v-on="on">
        <template v-if="responsive">
          <span class="d-flex d-sm-none">{{ app_currency.toLowerCase() }}</span>
          <span class="d-none d-sm-flex">{{ currencyList[app_currency].toLowerCase() }}</span>
        </template>
        <span v-else>{{ currencyList[app_currency].toLowerCase() }}</span>
      </a>
    </template>

    <v-list>
      <v-list-item
        v-for="(v, index) in Object.keys(currencyList)"
        :key="index"
        :disabled="app_currency === v"
        @click="app_currency = v"
      >
        <v-list-item-title>{{ `${currencyList[v]} (${v})` }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

</template>

<script>
import CONFIG from '~/assets/config/constants'
import appSettingsMixin from '@/components/_lib/mixins/appSettings.js'

export default {
  mixins: [appSettingsMixin],
  props: {
    selectorClass: {
      type: String,
      default: ''
    },
    responsive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currencyList: CONFIG.CURRENCY
    }
  }
}
</script>
