<!--  Standard chapter for home page and other overview pages such as services

Note: no need to import as it is part of ~/plugins/components.js

******  Examples:

  <c-chapter
    :id="item.id"
    anchor
    title-center
    :title="$t('services.timeline.title')"
    :intro="$t('services.timeline.intro')"
  /> ... </c-chapter>

  // For convenience, title, subtitle and intro derived from one i18n key:
  <c-chapter
    :msg-prefix="services.process"
  > ... </c-chapter>

  // With slots:
  <c-chapter
    :title="$t('services.timeline.title')"
    split-col
  >
    <template v-slot:rightborder>
      <v-card >
        <services-tree />
      </v-card>
    </template>

    <template v-slot:intro>
      <p v-html="$t('services.timeline.intro')" />
    </template>

    ...
  </c-chapter>

**********

-->

<template>
  <v-container fluid class="mb-0 pb-0 mx-auto" style="max-width:1920px;">
    <div v-if="anchor" :id="id" class="scroll-margin-top" />

    <v-row class="mb-0 pb-0" :class="{'d-flex align-center': alignCenter}">
      <v-col
        v-if="iTitle && wideTitle"
        cols="12"
        :class="{
          'offset-lg-2': !narrow && (!imgLeft || !imgSrc),
          'offset-lg-3': narrow && (!imgLeft || !imgSrc),
          'offset-md-1': narrow }"
      >
        <c-spacer half />

        <h1
          class="info--text my-4 mb-sm-6"
          :class="{ center: titleCenter || $vuetify.breakpoint.smAndDown}"
        >{{ iTitle }}</h1>
      </v-col>

      <!-- Image in left margin on wide screens -->
      <v-col
        v-if="imgSrc && imgLeft"
        cols="12"
        :lg="narrow ? 3 : 2"
        class="d-none d-lg-block pr-4 "
        :class="{ 'align-self-lg-center': !imgTop }"
        style="margin-bottom: -100px;"
      >
        <v-img
          :alt="imgAlt"
          :src="imgSrc"
          :height="imgMaxHeight"
          contain
          position="bottom"
          class="d-none d-lg-block"
        />
      </v-col>

      <v-col
        cols="12"
        :sm="splitCol ? ( wideTitle ? 6 : 5 ) : ( narrow ? 10 : 12 )"
        :lg="splitCol ? ( wideTitle ? 4 : 3 ) : ( narrow ? 6 : 8 )"
        :class="{
          'offset-lg-2': !narrow && (!imgLeft || !imgSrc),
          'offset-lg-3': narrow && (!imgLeft || !imgSrc),
          'offset-md-1': narrow }"
        class="pt-0 pb-0 "
      >
        <template v-if="iTitle && !wideTitle">
          <c-spacer half />

          <h1
            class="info--text my-4 mb-sm-6"
            :class="{ center: titleCenter || $vuetify.breakpoint.smAndDown}"
          >{{ iTitle }}</h1>
        </template>

        <template v-if="iSubtitle">
          <c-spacer half />
          <h1 class="d-block my-sm-4" :class="{ center: titleCenter }" v-html="iSubtitle" />
          <c-spacer />
        </template>

        <template v-if="iIntro">
          <c-spacer half />
          <p class="paragraph" v-html="iIntro" />
          <!-- <p class="paragraph mb-4" :class="{ 'text-center': !splitCol}" v-html="iIntro" /> -->
        </template>

        <template v-else>
          <slot name="intro" />
        </template>

        <!-- Actual content slot full width-->
        <slot v-if="!splitCol" />
      </v-col>

      <!-- Actual content slot smaller-->
      <v-col
        v-if="splitCol"
        cols="12"
        :sm="( wideTitle ? 6 : 7 )"
        :lg="( wideTitle ? 4 : 5 )"
      >

        <slot />
      </v-col>

      <!-- Image in right margin on wide screens -->
      <!-- v-if="(imgSrc && !imgLeft) || $slots.rightborder" -->
      <v-col
        cols="12"
        :lg="narrow ? 3 : 2"
        class="d-none d-lg-block pt-3 "
      >
        <v-img
          v-if="(imgSrc && !imgLeft)"
          :alt="imgAlt"
          :src="imgSrc"
          :height="imgMaxHeight"
          contain
          position="bottom"
          :class="{ 'align-self-lg-center': !imgTop }"
          style="margin-bottom: -100px;"
        />
        <slot name="rightborder" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Chapter',
  props: {
    // anchor to allow scroll to right position; id is required to make it work
    anchor: { type: Boolean, default: false },
    id: { type: String, default: '' },

    // Layout
    // splitCol = false: title + intro + slot in one column
    // splitCol = true:  title + intro in first column, slot moved to a second column
    splitCol: { type: Boolean, default: false },
    narrow: { type: Boolean, default: false },
    wideTitle: { type: Boolean, default: false },
    alignCenter: { type: Boolean, default: false },
    // optional side image is hidden on  devices < lg

    //   img-left to show image not on the right

    //   To append link to the *intro* text:
    //   to="/about#professionals"
    //   link="more details click here"  // default is $t("def.more")

    // Outside margin image
    imgSrc: { type: String, default: '' },
    imgAlt: { type: String, default: 'Bligson Workflow' },
    imgMaxHeight: { type: String, default: '330px' },
    imgLeft: { type: Boolean, default: false },
    imgTop: { type: Boolean, default: false },

    // content fields
    title: { type: String, default: '' },
    titleCenter: { type: Boolean, default: false },
    subtitle: { type: String, default: '' },
    intro: { type: String, default: '' },
    msgPrefix: { type: String, default: '' }
    // for convenience, you can pass all title,subtitle,intro at once with e.g.: msg-prefix="company"
  },
  data () {
    return {
      iTitle: this.title || this.i18nMessage('title'),
      iSubtitle: this.subtitle || this.i18nMessage('subtitle'),
      iIntro: this.intro || this.i18nMessage('intro')
    }
  },
  methods: {
    nrCol () {
      if (!this.imgSrc) { return 1 }
      if (this.ImgSrc && (this.title || this.iIntro)) { return 2 }
    },
    i18nMessage (prefix) {
      if (this.msgPrefix) {
        return this.$i18n.te(`${this.msgPrefix}.${prefix}`, 'en') ? this.$i18n.t(`${this.msgPrefix}.${prefix}`) : ''
      } else {
        return null
      }
    }
  }
}
</script>
