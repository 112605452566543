<template>
  <v-toolbar-items>
    <top-nav-menu :label="$t('solution.labels')" to="/solutions">
      <solutions-menu />
    </top-nav-menu>

    <top-nav-menu :label="$t('industry.label')" :to="'/industry'">
      <industry-menu />
    </top-nav-menu>

    <top-nav-menu :label="$t('service.labels')" to="/service">
      <services-menu />
    </top-nav-menu>

    <c-nav-button small text to="/partners">
      {{ $t('partners.label') }}
    </c-nav-button>

    <c-nav-button small text to="/pricing">
      {{ $t('pricing.label') }}
    </c-nav-button>

    <top-nav-menu :label="$t('company.label')" to="/company">
      <company-menu />
    </top-nav-menu>

    <c-nav-button small text to="/insights">
      {{ $t('blog.label') }}
    </c-nav-button>
  </v-toolbar-items>
</template>

<script>

import TopNavMenu from '@/components/default/menu/_TopNavMenu.vue'

import IndustryMenu from '@/components/default/menu/IndustryMenu.vue'
import SolutionsMenu from '@/components/default/menu/SolutionsMenu'
import ServicesMenu from '@/components/default/menu/ServicesMenu'
import CompanyMenu from '@/components/default/menu/CompanyMenu.vue'

export default {
  components: {
    TopNavMenu,
    IndustryMenu,
    ServicesMenu,
    SolutionsMenu,
    CompanyMenu
  },
  computed: {
    pageId () {
      return this.$route.params && this.$route.params.id ? this.$route.params.id : null
    }
  }
}
</script>
