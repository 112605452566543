<template>
  <div class="mr-sm-12">
    <a
      v-for="brand in brands"
      :key="brand.name"
      :href="brand.link"
      target="_blank"
      class="px-2"
      style="text-decoration: none;"
      rel="noopener"
      :aria-label="brand.name"
    >
      <v-icon size="20" color="primary">{{ brand.icon }}</v-icon>
    </a>
  </div>
</template>

<script>
import {
  mdiFacebook,
  mdiLinkedin,
  mdiTwitter

} from '@mdi/js'
import CONFIG from '~/assets/config/constants'

export default {
  data: () => ({
    brands: [
      // { name: 'Website', link: 'https://', icon: 'web' },
      { name: 'Facebook', link: CONFIG.SOCIAL_MEDIA.Facebook, icon: mdiFacebook },
      { name: 'LinkedIn', link: CONFIG.SOCIAL_MEDIA.LinkedIn, icon: mdiLinkedin },
      { name: 'Twitter', link: CONFIG.SOCIAL_MEDIA.Twitter, icon: mdiTwitter }
    ]

  })
}
</script>
