import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12af40a4 = () => interopDefault(import('../pages/industry.vue' /* webpackChunkName: "pages/industry" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _436eabfb = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _08eb63b4 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _4e24347a = () => interopDefault(import('../pages/solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _8d20773c = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _40aeda24 = () => interopDefault(import('../pages/company/contact.vue' /* webpackChunkName: "pages/company/contact" */))
const _df337b56 = () => interopDefault(import('../pages/company/terms.vue' /* webpackChunkName: "pages/company/terms" */))
const _57acb6b8 = () => interopDefault(import('../pages/service/bligsy.vue' /* webpackChunkName: "pages/service/bligsy" */))
const _cce2e21c = () => interopDefault(import('../pages/service/coaching.vue' /* webpackChunkName: "pages/service/coaching" */))
const _097ff73e = () => interopDefault(import('../pages/service/deliverables.vue' /* webpackChunkName: "pages/service/deliverables" */))
const _400bc3ae = () => interopDefault(import('../pages/service/workflow.vue' /* webpackChunkName: "pages/service/workflow" */))
const _14271730 = () => interopDefault(import('../pages/company/_about.vue' /* webpackChunkName: "pages/company/_about" */))
const _5872b722 = () => interopDefault(import('../pages/insights/_tag.vue' /* webpackChunkName: "pages/insights/_tag" */))
const _7e3b771b = () => interopDefault(import('../pages/post/_id.vue' /* webpackChunkName: "pages/post/_id" */))
const _14df4669 = () => interopDefault(import('../pages/service/_framework.vue' /* webpackChunkName: "pages/service/_framework" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/industry",
    component: _12af40a4,
    name: "industry___en"
  }, {
    path: "/nl",
    component: _e542f99c,
    name: "index___nl"
  }, {
    path: "/partners",
    component: _436eabfb,
    name: "partners___en"
  }, {
    path: "/pricing",
    component: _08eb63b4,
    name: "pricing___en"
  }, {
    path: "/solutions",
    component: _4e24347a,
    name: "solutions___en"
  }, {
    path: "/test",
    component: _8d20773c,
    name: "test___en"
  }, {
    path: "/company/contact",
    component: _40aeda24,
    name: "company-contact___en"
  }, {
    path: "/company/terms",
    component: _df337b56,
    name: "company-terms___en"
  }, {
    path: "/nl/industry",
    component: _12af40a4,
    name: "industry___nl"
  }, {
    path: "/nl/partners",
    component: _436eabfb,
    name: "partners___nl"
  }, {
    path: "/nl/pricing",
    component: _08eb63b4,
    name: "pricing___nl"
  }, {
    path: "/nl/solutions",
    component: _4e24347a,
    name: "solutions___nl"
  }, {
    path: "/nl/test",
    component: _8d20773c,
    name: "test___nl"
  }, {
    path: "/service/bligsy",
    component: _57acb6b8,
    name: "service-bligsy___en"
  }, {
    path: "/service/coaching",
    component: _cce2e21c,
    name: "service-coaching___en"
  }, {
    path: "/service/deliverables",
    component: _097ff73e,
    name: "service-deliverables___en"
  }, {
    path: "/service/workflow",
    component: _400bc3ae,
    name: "service-workflow___en"
  }, {
    path: "/nl/company/contact",
    component: _40aeda24,
    name: "company-contact___nl"
  }, {
    path: "/nl/company/terms",
    component: _df337b56,
    name: "company-terms___nl"
  }, {
    path: "/nl/service/bligsy",
    component: _57acb6b8,
    name: "service-bligsy___nl"
  }, {
    path: "/nl/service/coaching",
    component: _cce2e21c,
    name: "service-coaching___nl"
  }, {
    path: "/nl/service/deliverables",
    component: _097ff73e,
    name: "service-deliverables___nl"
  }, {
    path: "/nl/service/workflow",
    component: _400bc3ae,
    name: "service-workflow___nl"
  }, {
    path: "/nl/company/:about?",
    component: _14271730,
    name: "company-about___nl"
  }, {
    path: "/nl/insights/:tag?",
    component: _5872b722,
    name: "insights-tag___nl"
  }, {
    path: "/nl/post/:id?",
    component: _7e3b771b,
    name: "post-id___nl"
  }, {
    path: "/nl/service/:framework?",
    component: _14df4669,
    name: "service-framework___nl"
  }, {
    path: "/company/:about?",
    component: _14271730,
    name: "company-about___en"
  }, {
    path: "/insights/:tag?",
    component: _5872b722,
    name: "insights-tag___en"
  }, {
    path: "/post/:id?",
    component: _7e3b771b,
    name: "post-id___en"
  }, {
    path: "/service/:framework?",
    component: _14df4669,
    name: "service-framework___en"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
