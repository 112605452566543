<!--  Insert Company Logo

Use example:  <logo animate />
-->

<template>
  <v-img
    alt="Bligson Logo"
    aspect-ratio="3"
    :src="require('@/assets/logo/bligson-logo-1200x400.svg')"
    :class="{'animate-company-logo':animate}"
  />
</template>

<script>
export default {
  props: {
    animate: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.animate-company-logo {
  transform: rotateY(180deg);
  animation: turn .5s ease-out forwards 1s;
}

@keyframes turn {
  100% {
    transform: rotateX(0deg);
  }
}
</style>
