<template>
  <c-nav-button
    v-bind="$attrs"
    to="/company/contact"
    exact-active-class="grey lighten-3"
  >
    {{ labelText || $t('company.contact.label') }}
  </c-nav-button>
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
      default: ''
    }
  }
}
</script>
